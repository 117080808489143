/* eslint-disable prettier/prettier */
import React from 'react';
import PropTypes from 'prop-types';
import { ErrorBoundary } from 'react-error-boundary';
import './ErrorFallback.css';
import { servicesManager } from '../../../../viewer/src/App';

const ErrorFallback = ({ error, componentStack, resetErrorBoundary }) => {
  return (
    <div className="ErrorFallback" role="alert">
      <p>Something went wrong.</p>
      <pre>{error.message}</pre>
      <pre>{componentStack}</pre>
    </div>
  );
};

const OHIFErrorBoundary = ({
  context = 'OHIF',
  onReset = () => { },
  onError = () => { },
  fallbackComponent,
  children,
}) => {
  const { UIModalService } = servicesManager.services;  
  const onErrorHandler = (error, componentStack) => {
    console.error(`${context} Error Boundary`, error, componentStack);
    onError(error, componentStack);
    {(context === "LeftSidePanel") && UIModalService.show({
      content: ErrorDialog,
      title: context === "LeftSidePanel" ? "The study couldn't be loaded" : `Something went wrong in ${context}`,
    });}
  };
  const ErrorDialog = () => {
    return (
      <div>
      {(context === "LeftSidePanel") &&
      (<div className="ErrorFallback" role="alert">
        <div className="ErrorBoundaryDialog">
          <h3 className="ErrorBoundaryDialogTitle">
          <span>{"Inadequate Hardware Resources"}</span>
          </h3>
        </div>
        <button
          className="btn btn-primary btn-sm ErrorBoundaryDialogButtonSidepanel"
          onClick={() => window.close()}
        >OK
        </button>
       </div>)}
      </div>
    );
  };

    const onResetHandler = () => {
    onReset();
  };

  return (
    <ErrorBoundary
      FallbackComponent={fallbackComponent || ErrorFallback}
      onReset={onResetHandler}
      onError={onErrorHandler}
    >
      {children}
    </ErrorBoundary>
  );
};

OHIFErrorBoundary.propTypes = {
  context: PropTypes.string,
  onReset: PropTypes.func,
  onError: PropTypes.func,
  children: PropTypes.node.isRequired,
  fallbackComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.func, PropTypes.element]),
};

export default OHIFErrorBoundary;
