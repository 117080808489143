import React from 'react';
import PropTypes from 'prop-types';

import { TabComponents } from '@ohif/ui';

// Tabs
import { HotkeysPreferences } from './HotkeysPreferences';
import { WindowLevelPreferences } from './WindowLevelPreferences';
// import { GeneralPreferences } from './GeneralPreferences';

import './UserPreferences.styl';
import { isMobile } from 'react-device-detect';

const tabs = [
  // {
  //   name: 'General',
  //   Component: GeneralPreferences,
  //   customProps: {},
  // },
  {
    name: 'Window Level',
    Component: WindowLevelPreferences,
    customProps: {},
  },
];
if (!isMobile) {
  tabs.unshift({
    name: 'Hotkeys',
    Component: HotkeysPreferences,
    customProps: {},
  });
}

function UserPreferences({ hide }) {
  const customProps = {
    onClose: hide,
  };
  return <TabComponents tabs={tabs} customProps={customProps} />;
}

UserPreferences.propTypes = {
  hide: PropTypes.func,
};

export { UserPreferences };
