export class PrivilegeId {
    static get S_VIEW_SYSTEM_ROLES() { return 'S_VIEW_SYSTEM_ROLES'; }
    static get S_CREATE_SYSTEM_ROLE() { return 'S_CREATE_SYSTEM_ROLE'; }
    static get S_UPDATE_SYSTEM_ROLE() { return 'S_UPDATE_SYSTEM_ROLE'; }
    static get S_DELETE_SYSTEM_ROLE() { return 'S_DELETE_SYSTEM_ROLE'; }

    static get S_VIEW_ALL_USERS() { return 'S_VIEW_ALL_USERS'; }
    static get S_DEACTIVATE_USER() { return 'S_DEACTIVATE_USER'; }
    static get S_ACTIVATE_USER() { return 'S_ACTIVATE_USER'; }

    static get S_CREATE_CENTER() { return 'S_CREATE_CENTER'; }
    static get S_UPDATE_CENTER() { return 'S_UPDATE_CENTER'; }
    static get S_DEACTIVATE_CENTER() { return 'S_DEACTIVATE_CENTER'; }
    static get S_ACTIVATE_CENTER() { return 'S_ACTIVATE_CENTER'; }

    static get SC_CREATE_CENTER_ROLE() { return 'SC_CREATE_CENTER_ROLE'; }
    static get SC_UPDATE_CENTER_ROLE() { return 'SC_UPDATE_CENTER_ROLE'; }
    static get SC_DELETE_CENTER_ROLE() { return 'SC_DELETE_CENTER_ROLE'; }
    static get SC_VIEW_CENTER_USERS() { return 'SC_VIEW_CENTER_USERS'; }
    static get SC_ASSIGN_CENTER_USERS() { return 'SC_ASSIGN_CENTER_USERS'; }
    static get SC_VIEW_DASHBOARD() { return 'SC_VIEW_DASHBOARD'; }
    static get SC_VIEW_AUDITLOG() { return 'SC_VIEW_AUDITLOG'; }
    static get SC_VIEW_STUDY() { return 'SC_VIEW_STUDY'; }
    static get SC_ACCESS_REPORT() { return 'SC_ACCESS_REPORT'; }
    static get SC_ASSIGN_STUDY() { return 'SC_ASSIGN_STUDY'; }
    static get SC_SET_PRIORITY() { return 'SC_SET_PRIORITY'; }
    static get SC_DELETE_STUDY_SERIES() { return 'SC_DELETE_STUDY_SERIES'; }
    static get SC_PRINT_REPORT() { return 'SC_PRINT_REPORT'; }
    static get SC_SEND() { return 'SC_SEND'; }

    static get C_CREATE_CENTER_ROLE() { return 'C_CREATE_CENTER_ROLE'; }
    static get C_UPDATE_CENTER_ROLE() { return 'C_UPDATE_CENTER_ROLE'; }
    static get C_DELETE_CENTER_ROLE() { return 'C_DELETE_CENTER_ROLE'; }
    static get C_VIEW_CENTER_USERS() { return 'C_VIEW_CENTER_USERS'; }
    static get C_ASSIGN_CENTER_USERS() { return 'C_ASSIGN_CENTER_USERS'; }
    static get C_VIEW_DASHBOARD() { return 'C_VIEW_DASHBOARD'; }
    static get C_VIEW_AUDITLOG() { return 'C_VIEW_AUDITLOG'; }
    static get C_VIEW_STUDY() { return 'C_VIEW_STUDY'; }
    static get C_ACCESS_REPORT() { return 'C_ACCESS_REPORT'; }
    static get C_ASSIGN_STUDY() { return 'C_ASSIGN_STUDY'; }
    static get C_SET_PRIORITY() { return 'C_SET_PRIORITY'; }
    static get C_DELETE_STUDY_SERIES() { return 'C_DELETE_STUDY_SERIES'; }
    static get C_PRINT_REPORT() { return 'C_PRINT_REPORT'; }
    static get C_SEND() { return 'C_SEND'; }
}

export class RolePrivilege {
    static get STATUS_ACTIVE() { return 0; }
    static get STATUS_INACTIVE() { return 1; }
    static get STATUS_DELETED() { return 2; }

    constructor(rolePrivilege) {
        this.PrivilegeId = rolePrivilege.privilegeId;
        this.Status = rolePrivilege.status;
    }

    static fromArray(jsonArray) {
        if (!jsonArray) {
            return [];
        }

        return jsonArray.map((rolePrivilege) => {
            return new RolePrivilege(rolePrivilege);
        });
    }
}

export class Role {
    static get STATUS_ACTIVE() { return 0; }
    static get STATUS_DELETED() { return 1; }

    static get ROLE_TYPE_SYSTEM() { return 0; }
    static get ROLE_TYPE_CENTER() { return 1; }

    static get ROLE_TYPE_SYSTEM_NAME() { return 'System'; }
    static get ROLE_TYPE_CENTER_NAME() { return 'Center'; }

    static get ROLE_TYPES() {
        return [
            { value: Role.ROLE_TYPE_SYSTEM, label: Role.ROLE_TYPE_SYSTEM_NAME },
            { value: Role.ROLE_TYPE_CENTER, label: Role.ROLE_TYPE_CENTER_NAME }
        ]
    }

    constructor(role) {
        if (!role) {
            this.Name = '';
            return;
        }
        this.Id = role.id;
        this.Name = role.name ?? '';
        this.Status = role.status;
        this.RoleType = role.roleType;
        this.CenterId = role.centerId;
        this.DefaultType = role.defaultType;
        this.CreatedById = role.createdById;
        this.CreatedAt = role.createdAt;
        this.Privileges = RolePrivilege.fromArray(role.privileges);
    }

    clone() {
        var copy = new Role();
        copy.Id = this.Id;
        copy.Name = this.Name ?? '';
        copy.Status = this.Status;
        copy.RoleType = this.RoleType;
        copy.CenterId = this.CenterId;
        copy.DefaultType = this.DefaultType;
        copy.createdById = this.createdById;
        copy.createdAt = this.createdAt;

        if (this.Privileges) {
            copy.Privileges = [...this.Privileges];
        }
        return copy;
    }

    static fromArray(jsonData) {
        if (!jsonData) {
            return [];
        }

        return jsonData.map((obj) => {
            return new Role(obj);
        })
    }

    get RoleTypeName() {
        return this.RoleType === Role.ROLE_TYPE_SYSTEM ? Role.ROLE_TYPE_SYSTEM_NAME : Role.ROLE_TYPE_CENTER_NAME;
    }

    get IsSystemRole() {
        return this.RoleType == Role.ROLE_TYPE_SYSTEM;
    }
}
export class Privilege {
    static get PRIVIELGE_TYPE_SYSTEM() { return 0; }
    static get PRIVIELGE_TYPE_CENTER() { return 1; }

    constructor(privilege) {
        this.Id = privilege.id ?? "";
        this.Name = privilege.name ?? "";
        this.PrivilegeType = privilege.privilegeType ?? 0;
    }

    static fromArray(jsonArray) {
        if (!jsonArray) {
            return [];
        }

        return jsonArray.map((privilege) => {
            return new Privilege(privilege);
        });
    }
}


export class CenterPrivilege {
    constructor(centerPrivilege) {
        this.CenterId = centerPrivilege.centerId;
        this.CenterCode = centerPrivilege.centerCode;
        this.CenterName = centerPrivilege.centerName;
        this.Privileges = RolePrivilege.fromArray(centerPrivilege.privileges);
    }

    static fromArray(jsonArray) {
        if (!jsonArray) {
            return [];
        }
        return jsonArray.map((centerPrivilege) => {
            return new CenterPrivilege(centerPrivilege);
        });
    }
}

export class Center {
    static get ALL_CENTERS_NAME() { return 'All Centers'; }

    constructor(center) {
        if (!center) {
        	this.Name = '';
        	this.Code = '';
            return;
        }

        this.Id = center.id;
        this.Name = center.name ?? '';
        this.Code = center.code ?? '';
        this.IsActive = center.isActive;
        this.MasterPrivileges = RolePrivilege.fromArray(center.masterPrivileges);
    }

    clone() {
        var copy = new Center();
        copy.Id = this.Id;
        copy.Name = this.Name ?? '';
        copy.Code = this.Code ?? '';
        copy.IsActive = this.IsActive;

        if (copy.MasterPrivileges) {
            copy.MasterPrivileges = [...this.MasterPrivileges];
        }
        return copy;
    }

    get IsActiveDisplay() {
        return this.IsActive ? "Active" : "Inactive";
    }

    GetMasterPrivilege(privilegeId) {
    	if (!this.MasterPrivileges) { return null; }
        return this.MasterPrivileges.find((mp) => mp.PrivilegeId == privilegeId);
    }

    static fromArray(jArray) {
        if (!jArray) {
            return [];
        }

        return jArray.map(obj => new Center(obj));
    }
}

export class User {
    static get STATUS_ACTIVE() { return 0; }
    static get STATUS_INACTIVE() { return 1; }

    static get USER_TYPE_NORMAL() { return 0; }
    static get USER_TYPE_SUPER_USER() { return 1; }
    static get USER_TYPE_FUJI_MAIN_ADMIN() { return 2; }

    constructor(user) {
        if (!user) {
        	this.Name = '';
        	this.Email = '';
        	this.Password = '';
            this.ConfirmPassword = '';
            this.HasAllCentersAssigned = false
            return;
        }
        this.Id = user.id;
        this.Name = user.name ?? '';
        this.IdentityObjectId = user.identityObjectId;
        this.Email = user.email ?? '';
        this.Status = user.status;
        this.UserType = user.userType;
        this.HasAllCentersAssigned = user.hasAllCentersAssigned;
        this.CreatedById = user.createdById;
        this.CreatedAt = user.createdAt;
        this.Password = user.password ?? '';
        this.ConfirmPassword = user.confirmPassword ?? '';

        this.AssignedCenters = [];
        if (user.assignedCenters) {
            this.AssignedCenters = user.assignedCenters.map((center) => {
                return new Center(center);
            })
        }

        this.AssignedRoles = [];
        if (user.assignedRoles) {
            this.AssignedRoles = user.assignedRoles.map((role) => {
                return new Role(role);
            })
        }

        this.CenterIdsToAssign = user.centerIdsToAssign ?? [];
        this.CenterIdsToUnAssign = user.centerIdsToUnAssign ?? [];

        this.RoleIdsToAssign = user.roleIdsToAssign ?? [];
        this.RoleIdsToUnAssign = user.roleIdsToUnAssign ?? [];
    }

    clone() {
        var copy = new User();
        copy.Id = this.Id;
        copy.Name = this.Name ?? '';
        copy.Email = this.Email ?? '';
        copy.IdentityObjectId = this.IdentityObjectId;
        copy.Status = this.Status;
        copy.UserType = this.UserType;
        copy.HasAllCentersAssigned = this.HasAllCentersAssigned;
        copy.CreatedById = this.CreatedById;
        copy.CreatedAt = this.CreatedAt;

        if (this.AssignedCenters) {
            copy.AssignedCenters = [...this.AssignedCenters];
        }

        if (this.AssignedRoles) {
            copy.AssignedRoles = [...this.AssignedRoles];
        }

        if (this.CenterIdsToAssign) {
            copy.CenterIdsToAssign = [...this.CenterIdsToAssign];
        }

        if (this.CenterIdsToUnAssign) {
            copy.CenterIdsToUnAssign = [...this.CenterIdsToUnAssign];
        }

        if (this.RoleIdsToAssign) {
            copy.RoleIdsToAssign = [...this.RoleIdsToAssign];
        }

        if (this.RoleIdsToUnAssign) {
            copy.RoleIdsToUnAssign = [...this.RoleIdsToUnAssign];
        }

        copy.Password = this.Password ?? '';
        copy.ConfirmPassword = this.ConfirmPassword ?? '';
        return copy;
    }

    static fromArray(jArray) {
        if (!jArray) {
            return [];
        }

        return jArray.map(obj => new User(obj));
    }

    get IsActive() {
        return this.Status == User.STATUS_ACTIVE;
    }

    get IsActiveDisplay() {
        return this.IsActive ? "Active" : "Inactive";
    }

    get IsNormalUser() {
        return this.UserType == User.USER_TYPE_NORMAL;
    }
    get IsSuperAdmin() {
        return this.UserType == User.USER_TYPE_SUPER_USER;
    }

    get IsFujiManiAdmin() {
        return this.UserType == User.USER_TYPE_FUJI_MAIN_ADMIN;
    }

    get UserType() {
        return this.userType;
    }

    set UserType(userType) {
        return this.userType = userType;
    }

    getCenter(centerCode) {
        if (!this.AssignedCenters) { return null; }
        return this.AssignedCenters.find((obj) => obj.Code === centerCode);
    }

    GetCenterList() {
        if (!this.AssignedCenters) { return []; }

        return this.AssignedCenters.map((obj) => {
            return obj.clone();
        })
    }

    GetSystemRoles() {
        if (!this.AssignedRoles) { return []; }
        return this.AssignedRoles.filter((sr) => sr.RoleType == Role.ROLE_TYPE_SYSTEM).map((obj) => obj.clone());
    }

    GetCenterRoles(centerId) {
        if (!this.AssignedRoles) { return []; }

        var filteredRoles = this.AssignedRoles.filter((sr) => {
            return sr.RoleType == Role.ROLE_TYPE_CENTER && sr.CenterId == centerId;
        });

        var clonedRoles = filteredRoles.map((obj) => {
            return obj.clone();
        });

        return clonedRoles;
    }

    GetCenterPrivileges(centerId) {
        if (!this.AssignedRoles) { return []; }
        return this.GetPrivilegesOfRoles(this.GetCenterRoles(centerId));
    }

    GetPrivilegesOfRoles(roles) {
        if (!roles) { return []; }
        var resultPrivileges = [];

        roles.forEach(role => {
            if (role.Privileges) {
                role.Privileges.forEach((privilege) => {
                    if (!resultPrivileges.find((rp) => rp.Id == privilege.PrivilegeId)) {
                        resultPrivileges.push(privilege);
                    }
                });
            }
        });
        return resultPrivileges;
    }

    HasSystemPrivilege(privilegeId) {
        if (!this.AssignedRoles) {
            return false;
        }
        return !!this.GetPrivilegesOfRoles(this.GetSystemRoles()).find((sp) => sp.PrivilegeId == privilegeId);
    }

    HasCenterPrivilege(privilegeId, centerCode) {
        var center = this.getCenter(centerCode);

        if (!center) {
            return false;
        }
        var centerPrivilege = this.GetCenterPrivileges(centerCode);

        if (!centerPrivilege.find((cp) => (cp.PrivilegeId == privilegeId && cp.Status == RolePrivilege.STATUS_ACTIVE))) {
            return false;
        }
        return true;
    }

    UpdateCenterDetails(center) {
        var target = this.AssignedCenters?.find((c) => c.Code === center.Code);
        if (!target) { return; }
        target.Name = center.Name;
    }

    IsRoleAssigned(roleId) {
        return this.AssignedRoles && this.AssignedRoles.find((role) => role.Id === roleId);
    }
}

/**
 * Class to contain the authenticated user's privilege information.
 * USAGE:
 *   var userInfo = new AuthInfoBase();
 *   userInfo.initialize('https://redicomcloud.azurewebsites.net/UM/MyAccount', 'TOKEN')
 * 
 *   var a = userInfo.HasCenterAccess('DC-1');
 *   var b = userInfo.HasSystemPrivilege(PrivilegeId.S_CREATE_CENTER);
 *   var c = userInfo.HasCenterPrivilege(PrivilegeId.C_ASSIGN_STUDY);
 *   var d = userInfo.HasAllCentersAssigned && userInfo.HasSystemPrivilege(PrivilegeId.SC_ASSIGN_STUDY);
 */
export class AuthInfoBase {
    GetCenter(centerCode) {
        return this.Account?.getCenter(centerCode);
    }

    GetCenterList() {
        return this.Account ? this.Account.GetCenterList() : [];
    }

    HasCenterAccess(centerCode) {
        return !!this.GetCenter(centerCode);
    }

    get Account() {
        return this.account;
    }

    set Account(account) {
        this.account = account;
    }

    get HasAllCentersAssigned() {
        return !!this.Account?.HasAllCentersAssigned;
    }

    HasSystemPrivilege(privilegeId) {
        return this.Account && this.Account.HasSystemPrivilege(privilegeId);
    }

    HasCenterPrivilege(privilegeId, centerId) {
        var center = this.GetCenter(centerId);

        if (center && this.Account && this.Account.HasCenterPrivilege(privilegeId, centerId)) {
            return true;
        }

        return false;
        // var center = this.GetCenter(centerId);
        // return center && center.HasMasterPrivilege(privilegeId);
    }

    get IsSuperAdmin() {
        return this.Account && this.Account.IsSuperAdmin;
    }

    get IsFujiMainAdmin() {
        return this.Account && this.Account.IsIsFujiManiAdmin;
    }

    async initialize(apiUrl, bearerToken) {
        return fetch(apiUrl,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': ' application/json',
                    'Authorization': bearerToken
                }
            })
            .then((response) => {
                return response.json().then((obj) => {
                    this.Account = new User(obj);
                    return this;
                });
            });
    }
}

