import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { redux } from '@ohif/core';

import { TabFooter, useSnackbarContext } from '@ohif/ui';
import { useTranslation } from 'react-i18next';

import './WindowLevelPreferences.styl';
// import { useHistory } from 'react-router-dom';

const { actions } = redux;

function WindowLevelPreferences({ onClose }) {
  const dispatch = useDispatch();

  const windowLevelData = useSelector(state => {
    const { preferences = {} } = state;
    const { windowLevelData } = preferences;

    return windowLevelData;
  });

  const [state, setState] = useState({
    values: { ...windowLevelData },
  });

  useEffect(() => {
    const localData = JSON.parse(localStorage.getItem('state'));
    if (windowLevelData != localData.preferences.windowLevelData) {
      setState({ values: { ...localData.preferences.windowLevelData } });
    }
  }, [localStorage.getItem('state')]);

  const { t } = useTranslation('UserPreferencesModal');
  const onResetPreferences = () => {};
  const hasErrors = false;
  // const redirect = useHistory();

  const onSave = () => {
    let validData = [];
    const results = Object.keys(state.values).map((key, index) => {
      if (
        state.values[key].description !== '' ||
        state.values[key].window !== '' ||
        state.values[key].level !== ''
      ) {
        if (
          state.values[key].description === '' ||
          state.values[key].window === '' ||
          state.values[key].level === ''
        ) {
          validData.push(index + 1);
        }
      }

      return {
        displayOrder: index + 1,
        description: state.values[key].description,
        window: state.values[key].window,
        level: state.values[key].level,
      };
    });

    if (validData.length === 0) {
      var headerData = new Headers();
      var idtoken = JSON.parse(
        sessionStorage.getItem(
          'oidc.user:' +
            window.config.oidc[0].authority +
            ':' +
            window.config.oidc[0].client_id
        )
      );
      var bearerToken = 'bearer ' + idtoken.id_token;
      headerData.append('Content-Type', 'application/json');
      headerData.append('Access-Control-Allow-Origin', '*');
      headerData.append('Authorization', bearerToken);

      var requestOptions = {
        method: 'POST',
        headers: headerData,
        body: JSON.stringify(results),
        redirect: 'follow',
      };

      fetch(
        `${window.config.servers.dicomWeb[0].apiRoot}/api/storeWindowLevel`,
        requestOptions
      )
        .then(response => response.text())
        .then(result => console.log(result))
        .catch(error => console.log('error', error));

      dispatch(actions.setUserPreferences({ windowLevelData: state.values }));
      // redirect.push('/');

      snackbar.show({
        message: t('SaveMessage'),
        type: 'success',
      });
      onClose();
    } else {
      snackbar.show({
        message: `Invalid data in preset ${validData}`,
        type: 'error',
      });
    }
    // window.location.reload();
  };

  const snackbar = useSnackbarContext();

  const handleInputChange = event => {
    const $target = event.target;
    const { key, inputname } = $target.dataset;
    let inputValue = $target.value;

    // if (!state.values[key] || !state.values[key][inputname]) {
    //   return;
    // }

    setState(prevState => ({
      ...prevState,
      values: {
        ...prevState.values,
        [key]: {
          ...prevState.values[key],
          [inputname]: inputValue,
        },
      },
    }));
  };

  return (
    <React.Fragment>
      <div className="WindowLevelPreferences">
        <div className="wlColumn">
          <div className="wlRow wlHeader">
            <div className="wlColumn preset">Preset</div>
            <div className="wlColumn description">Description</div>
            <div className="wlColumn window">Window</div>
            <div className="wlColumn level">Level</div>
          </div>
          {Object.keys(state.values).map((key, index) => {
            return (
              <div className="wlRow" key={key}>
                <div className="wlColumn preset">{index + 1}</div>
                <div className="wlColumn description">
                  <input
                    type="text"
                    maxLength={20}
                    className="preferencesInput"
                    value={state.values[key].description}
                    data-key={key}
                    data-inputname="description"
                    onChange={handleInputChange}
                  />
                </div>
                <div className="wlColumn window">
                  <input
                    type="number"
                    className="preferencesInput"
                    value={state.values[key].window}
                    data-key={key}
                    data-inputname="window"
                    min={1}
                    onChange={e => {
                      if (e.target.value <= 0) {
                        e.target.value = '';
                      }
                      if (e.target.value.length > 5) {
                        e.target.value = e.target.value.slice(0, 5);
                      }
                      e.target.value = parseInt(e.target.value);
                      handleInputChange(e);
                    }}
                  />
                </div>
                <div className="wlColumn level">
                  <input
                    type="number"
                    className="preferencesInput"
                    value={state.values[key].level}
                    data-key={key}
                    data-inputname="level"
                    onChange={e => {
                      if (e.target.value.length > 5) {
                        e.target.value = e.target.value.slice(0, 5);
                      }
                      e.target.value = e.target.value.replace('.', '');
                      try {
                        JSON.parse(e.target.value);
                      } catch (error) {
                        e.target.value = '';
                      }
                      handleInputChange(e);
                    }}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <TabFooter
        onResetPreferences={onResetPreferences}
        onSave={onSave}
        onCancel={onClose}
        hasErrors={hasErrors}
        t={t}
      />
    </React.Fragment>
  );
}

WindowLevelPreferences.propTypes = {
  onClose: PropTypes.func,
};

export { WindowLevelPreferences };
