import React from 'react';
import PropTypes from 'prop-types';
import './ConfirmationPopup.styl';

function ConfirmationPopup(props) {
  const { message } = props;

  const RenderBody = () => {
    return (
      <div className="confirmation-popup">
        <div className="message-div">
          <div>{message}</div>
        </div>
      </div>
    );
  };

  return <RenderBody />;
}

ConfirmationPopup.propTypes = {
  message: PropTypes.any,
};

export default ConfirmationPopup;
