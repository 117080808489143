import React,{ Fragment } from "react";
import ReactDOM from 'react-dom';
import './CusModal.css';

const Backdrop = props =>{
    return <div className='backdrop'></div>
};

const ModalOverlay = props =>{
  return <div className="modal">{props.children}</div>;
};
const portalElement = document.getElementById('overlays');
const CusModal = (props) =>{

    return<Fragment>
            {ReactDOM.createPortal(<Backdrop/>,portalElement)}
            {ReactDOM.createPortal(<ModalOverlay>{props.children}</ModalOverlay>,portalElement)}
        </Fragment>
    
};

export default CusModal;