import React from 'react';
import PropTypes from 'prop-types';
import ReportPdfFrame from '../../../../viewer/src/ReportPdfFrame/ReportPdfFrame';
import './ViewReportPdf.styl';
function ViewReportPdf(props) {
  const { StudyInstanceUID, downloadURL } = props;
  return (
    <div className="viewReport">
      <ReportPdfFrame
        AccessionNumber={null}
        StudyInstanceUID={StudyInstanceUID}
        className="view-pdf-report"
        downloadURL={downloadURL}
      />
    </div>
  );
}
ViewReportPdf.propTypes = {
  StudyInstanceUID: PropTypes.string,
  downloadURL: PropTypes.string,
};
export default ViewReportPdf;
