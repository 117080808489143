import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useSnackbarContext, ErrorPage } from '@ohif/ui';
import './CommentsContainer.css';
function CommentsContainer({ study, stdyUID, user }) {
  const snackbar = useSnackbarContext();
  const [saveButton, setSaveButton] = useState(true);
  const BaseURL = window.config.servers.dicomWeb[0].wadoRoot;
  const [commentsArray, setCommentsArray] = useState([]);

  // used to set error on api calls
  const [error, setError] = useState(false);
  const [errorDetails, setErrorDetails] = useState({});

  useEffect(() => {
    fetchClinicalNotes();
  }, [stdyUID]);

  //To fetch the clinical note
  async function fetchClinicalNotes() {
    var headerData = new Headers();
    var idtoken = JSON.parse(
      sessionStorage.getItem(
        'oidc.user:' +
          window.config.oidc[0].authority +
          ':' +
          window.config.oidc[0].client_id
      )
    );
    var bearerToken = 'bearer ' + idtoken.id_token;
    headerData.append('Content-Type', 'application/json');
    headerData.append('Access-Control-Allow-Origin', '*');
    headerData.append('Authorization', bearerToken);

    var requestOptions = {
      method: 'GET',
      headers: headerData,
      redirect: 'follow',
    };
    let errordetails = {};
    await fetch(`${BaseURL}fetchClinicalNotes/${stdyUID}/`, requestOptions)
      .then(response => {
        if (response.status === 200 || response.status === 400) {
          setError(false);
          return response.json();
        } else {
          setError(true);
          errordetails = {
            title: response.statusText,
          };
          return response.text();
        }
      })
      .then(result =>
        typeof result === 'string'
          ? setErrorDetails({ ...errordetails, description: result })
          : setCommentsArray(result)
      )
      .catch(error => {
        console.error('error', error);
      });
  }
  function formatDisplayTime(dateTime) {
    const adjustedDateTime = new Date(dateTime);
    const offset = adjustedDateTime.getTimezoneOffset();
    const sign = offset > 0 ? -1 : 1;
    const delayMinutes = Math.abs(offset) * sign;
    adjustedDateTime.setMinutes(adjustedDateTime.getMinutes() + delayMinutes);

    return adjustedDateTime.toLocaleString('en-US', {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
    });
  }

  // For handling the special keys
  const validateSpecialChars = event => {
    // Get the key code
    let keycode = event.which || event.keyCode;
    var textBoxString = document.getElementById('commentTextBox');

    if (
      (textBoxString.value.length > 0 && keycode === 32) ||
      keycode === 16 ||
      keycode === 46 ||
      keycode === 44 ||
      keycode === 34 ||
      keycode === 39 ||
      keycode === 40 ||
      keycode === 41 ||
      keycode === 45 ||
      keycode === 95 ||
      keycode === 64 ||
      keycode === 42 ||
      keycode === 91 ||
      keycode === 93 ||
      keycode === 47 ||
      keycode === 33 ||
      keycode === 37 ||
      keycode === 38 ||
      keycode === 58 ||
      keycode === 59 ||
      keycode === 35
    ) {
      return true;
    } //for space and hyphen
    // Check if key pressed is a special character
    else if (
      keycode < 48 ||
      (keycode > 57 && keycode < 65) ||
      (keycode > 90 && keycode < 97) ||
      keycode > 122
    ) {
      // Restrict the special characters
      event.preventDefault();
      return false;
    }
  };
  // To post the the notes
  async function toAddToTheCommentsArray(e) {
    setSaveButton(true);
    e.preventDefault();
    var splitedString = [];
    var textBoxString = document.getElementById('commentTextBox');
    const containsSpecialChars = str => {
      const specialChars = /[`^+\=\[\]{}\\|<>\/~]/;
      return specialChars.test(str);
    };
    if (containsSpecialChars(textBoxString.value)) {
      snackbar.show({
        message: 'Contains special charecters',
        type: 'warning',
      });
      return;
    }
    if (textBoxString.value !== '') {
      const tempArray = textBoxString.value;
      const splitLength = 80;
      if (tempArray.length > 80) {
        for (let index = 0; index < tempArray.length; index += splitLength) {
          splitedString.push(
            tempArray.substring(index, splitLength * (splitedString.length + 1))
          );
        }
        splitedString = splitedString.join(' ');
      }

      const comments = tempArray.length > 80 ? splitedString : tempArray;

      var headerData = new Headers();
      var idtoken = JSON.parse(
        sessionStorage.getItem(
          'oidc.user:' +
            window.config.oidc[0].authority +
            ':' +
            window.config.oidc[0].client_id
        )
      );
      var bearerToken = 'bearer ' + idtoken.id_token;
      headerData.append('Content-Type', 'application/json');
      headerData.append('Access-Control-Allow-Origin', '*');
      headerData.append('Authorization', bearerToken);

      var raw = JSON.stringify({ comment: comments });

      var requestOptions = {
        method: 'POST',
        headers: headerData,
        body: raw,
        redirect: 'follow',
      };
      let errordetails = {};
      await fetch(`${BaseURL}PostClinicalNotes/${stdyUID}/`, requestOptions)
        .then(response => {
          if (response.status === 200) {
            setError(false);
            setSaveButton(false);
          } else {
            setError(true);
            errordetails = {
              title: response.statusText,
              // description: 'Unauthorized User or Inactive Center',
            };
            return response.text();
          }
        })
        .then(result =>
          setErrorDetails({ ...errordetails, description: result })
        )
        .catch(error => console.log('error', error));
      textBoxString.value = '';

      fetchClinicalNotes();
    }
  }
  if (error) {
    return (
      <>
        <ErrorPage
          error={errorDetails.error}
          title={errorDetails.title}
          description={errorDetails.description}
          // onRetry={() => {}}
        />
      </>
    );
  }

  return (
    <div className="comments-body">
      <table className="patient-details">
        <tbody>
          <tr>
            <th>Patient Name</th>
            <td title={study.PatientName}>{study.PatientName}</td>
          </tr>
          <tr>
            <th>Patient ID</th>
            <td title={study.PatientID}>{study.PatientID}</td>
          </tr>
          <tr>
            <th>Patient Age</th>
            <td title={study.PatientBirthdate}>{study.PatientBirthdate}</td>
          </tr>
          <tr>
            <th>Patient Sex</th>
            <td title={study.PatientSex}>{study.PatientSex}</td>
          </tr>
          <tr>
            <th>Study Date</th>
            <td title={study.StudyDate}>{study.StudyDate}</td>
          </tr>
          <tr>
            <th>Study Description</th>
            <td title={study.StudyDescription}>{study.StudyDescription}</td>
          </tr>
          <tr>
            <th>Modalities</th>
            <td title={study.modalities}>{study.modalities}</td>
          </tr>
        </tbody>
        {/* <tr>
              <th>Institution Name</th>
              <td>FujiFilm</td>
            </tr> */}
      </table>
      <div className="theWholeCommentsContainer">
        <div className="comments">
          {commentsArray &&
            commentsArray.length > 0 &&
            commentsArray
              .sort(
                (a, b) =>
                  new Date(new Date(a.uploadDateTime)) -
                  new Date(new Date(b.uploadDateTime))
              )
              .map((item, index) => (
                <div key={`${item.comment}-${index}`} className="eachComments">
                  <div className="user-date">
                    <p className="user">{item.username}</p>
                    <p className="date">
                      {formatDisplayTime(item.uploadDateTime)}
                    </p>
                  </div>
                  <h5>{item.comment}</h5>
                </div>
              ))}
        </div>
        <div className="textBoxAndSaveButton">
          <input
            type="textarea"
            maxLength={200}
            onKeyPress={validateSpecialChars}
            id="commentTextBox"
            placeholder="Comments..."
            onChange={e => {
              if (e.target.value === '') {
                setSaveButton(true);
              } else {
                setSaveButton(false);
              }
            }}
          />
          <button
            className="commentsSaveButton"
            disabled={saveButton}
            type="button"
            onClick={e => toAddToTheCommentsArray(e)}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
}
CommentsContainer.propTypes = {
  study: PropTypes.object,
  stdyUID: PropTypes.string,
  user: PropTypes.object,
};
export default CommentsContainer;
