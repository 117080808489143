import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbarContext } from '../../../../ui/src/contextProviders';
import InputBox from '../InputBox/InputBox';
import HeaderComponent from '../HeaderComponent/HeaderComponent';

import './ResetPassword.styl';

export default function ResetPassword() {
  const snackbar = useSnackbarContext();
  const idtoken = JSON.parse(
    sessionStorage.getItem(
      'oidc.user:' +
        window.config.oidc[0].authority +
        ':' +
        window.config.oidc[0].client_id
    )
  );
  const [activeInputField, setActiveInputField] = useState('');
  const [filledInputFields, setFilledInputFields] = useState([
    'UserName',
    'Email',
  ]);
  const [password, setPassword] = useState({
    username: idtoken.profile.name,
    email: idtoken.profile.email,
    CurrentPassword: '',
    NewPassword: '',
  });
  const [confirmPassword, setConfirmPassword] = useState('');
  document.addEventListener('focusout', event => {
    if (event.target.className === 'resetPasswordInputField') {
      setActiveInputField('');
    }
  });
  document.addEventListener('focusin', event => {
    if (event.target.className === 'resetPasswordInputField') {
      setActiveInputField(event.target.name);
    }
  });
  const HandleResetPasswordInputFieldChange = e => {
    const { name, value } = e.target;

    if (value !== '') {
      if (!filledInputFields.find(item => item === name))
        setFilledInputFields([...filledInputFields, name]);
    } else {
      setFilledInputFields(filledInputFields.filter(item => item !== name));
    }
    setPassword(state => {
      return {
        ...state,
        [name]: value,
      };
    });
    if (name === 'ConfirmPassword') {
      setConfirmPassword(value);
    }
  };

  const passwordStrengthValidate = () => {
    const uppercaseRegExp = /(?=.*?[A-Z])/;
    const lowercaseRegExp = /(?=.*?[a-z])/;
    const digitsRegExp = /(?=.*?[0-9])/;
    const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
    const minLengthRegExp = /.{8,}/;

    const uppercasePassword = uppercaseRegExp.test(password.NewPassword);
    const lowercasePassword = lowercaseRegExp.test(password.NewPassword);
    const digitsPassword = digitsRegExp.test(password.NewPassword);
    const specialCharPassword = specialCharRegExp.test(password.NewPassword);
    const minLengthPassword = minLengthRegExp.test(password.NewPassword);

    if (!uppercasePassword) {
      return false;
    }
    if (!lowercasePassword) {
      return false;
    }
    if (!digitsPassword) {
      return false;
    }
    if (!specialCharPassword) {
      return false;
    }
    if (!minLengthPassword) {
      return false;
    }
    return true;
  };

  const validate = () => {
    var error = { hasError: true, errorMessage: '' };

    if (!password.CurrentPassword?.length) {
      error = { hasError: false, errorMessage: '' };
      return error;
    }

    if (!password.NewPassword?.length) {
      error = { hasError: false, errorMessage: '' };
      return error;
    }

    if (!confirmPassword?.length) {
      error = { hasError: false, errorMessage: '' };
      return error;
    }

    if (password.NewPassword != confirmPassword) {
      error = {
        hasError: false,
        errorMessage: 'The passwords you entered do not match.',
      };
      return error;
    }

    if (!passwordStrengthValidate()) {
      error = {
        hasError: false,
        errorMessage: 'Please choose a stronger password.',
      };
      return error;
    }

    return error;
  };

  const updatePassword = async () => {
    if (!validate().hasError) {
      snackbar.show({
        message:
          validate().errorMessage != ''
            ? validate().errorMessage
            : 'Mandatory field cannot be left blank.',
        type: 'error',
      });
      return;
    }

    var headerData = new Headers();
    var bearerToken = 'bearer ' + idtoken.access_token;
    headerData.append('Content-Type', 'application/json');
    headerData.append('Access-Control-Allow-Origin', '*');
    headerData.append('Authorization', bearerToken);

    var raw = JSON.stringify({
      username: password.email,
      Password: password.CurrentPassword,
      NewPassword: password.NewPassword,
    });

    var requestOptions = {
      method: 'PATCH',
      headers: headerData,
      body: raw,
      redirect: 'follow',
    };

    await fetch(
      `${window.config.servers.dicomWeb[0].identityUserApi}/UserRepository/UpdatePassword`,
      requestOptions
    )
      .then(response => response.json())
      .then(result => {
        if (result.success) {
          snackbar.show({
            message: 'Password reset done successfully.',
            type: 'success',
          });
          history.push('/');
        } else {
          snackbar.show({
            message: result.status,
            type: 'error',
          });
        }
      })
      .catch(error => {
        snackbar.show({
          message: 'Something Went Wrong...',
          type: 'error',
        });
        console.error('error', error);
      });
  };

  const submitPassword = () => {
    updatePassword();
  };

  const history = useHistory();

  return (
    <div className="ResetPasswordContainer">
      <div className="ResetPasswordContainer-mob">
        <HeaderComponent
          user={idtoken}
          headerName="Update Password"
          SubmitClick={submitPassword}
          CancelClick={() => history.push('/')}
        />
      </div>
      <div className="updatePasswordContainer">
        <InputBox
          className="resetPasswordInputFieldContainer filled"
          name="UserName"
          type="text"
          placeholder="User Name"
          value={password.username}
          labelName="User Name *"
          isdisabled={true}
          inputFieldClassName="resetPasswordInputField"
          labelClassName="resetPasswordLabels"
          OnChange={() => {}}
        />
        <InputBox
          className="resetPasswordInputFieldContainer filled"
          name="Email"
          type="email"
          placeholder="Email"
          value={password.email}
          labelName="Email *"
          isdisabled={true}
          inputFieldClassName="resetPasswordInputField"
          labelClassName="resetPasswordLabels"
          OnChange={() => {}}
        />
        <InputBox
          className={`resetPasswordInputFieldContainer ${
            activeInputField === 'CurrentPassword' ? 'active' : ''
          } ${
            filledInputFields.find(item => item === 'CurrentPassword')
              ? 'filled'
              : ''
          }`}
          name="CurrentPassword"
          type="password"
          placeholder="Current Password"
          value={password.CurrentPassword}
          labelName="Current Password *"
          isdisabled={false}
          inputFieldClassName="resetPasswordInputField"
          labelClassName="resetPasswordLabels"
          OnChange={e => HandleResetPasswordInputFieldChange(e)}
        />
        <InputBox
          className={`resetPasswordInputFieldContainer ${
            activeInputField === 'NewPassword' ? 'active' : ''
          } ${
            filledInputFields.find(item => item === 'NewPassword')
              ? 'filled'
              : ''
          }`}
          name="NewPassword"
          type="password"
          placeholder="New Password"
          value={password.NewPassword}
          labelName="New Password *"
          isdisabled={false}
          inputFieldClassName="resetPasswordInputField"
          labelClassName="resetPasswordLabels"
          OnChange={e => HandleResetPasswordInputFieldChange(e)}
        />
        <InputBox
          className={`resetPasswordInputFieldContainer ${
            activeInputField === 'ConfirmPassword' ? 'active' : ''
          } ${
            filledInputFields.find(item => item === 'ConfirmPassword')
              ? 'filled'
              : ''
          }`}
          name="ConfirmPassword"
          type="password"
          placeholder="Confirm Password"
          value={confirmPassword}
          labelName="Confirm Password *"
          isdisabled={false}
          inputFieldClassName="resetPasswordInputField"
          labelClassName="resetPasswordLabels"
          OnChange={e => HandleResetPasswordInputFieldChange(e)}
        />
      </div>
    </div>
  );
}
