import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import './orientationDetector.css';
const OrientationWarning = () => {
  let portrait = window.matchMedia('(orientation: portrait)');
  const [isLandscape, setIsLandscape] = useState(!portrait.matches && isMobile);

  portrait.addEventListener('change', function(e) {
    if (e.matches) {
      setIsLandscape(false);
    } else {
      setIsLandscape(true);
    }
  });

  if (!isLandscape) {
    return null;
  } else
    return (
      <div className="orientation-warning">Please switch to portrait mode.</div>
    );
};

export default OrientationWarning;
