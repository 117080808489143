import React from 'react';
import './AttachDocument.css';
import { useState, useEffect } from 'react';
import Uploader from './Uploader';
import DocTable from './DocTable';
import axios from 'axios';
import PropTypes from 'prop-types';
import { ErrorPage } from '@ohif/ui';

function AttachDocument({ stdyUID }) {
  const BaseURL = window.config.servers.dicomWeb[0].wadoRoot;
  const url = `${BaseURL}doc/fetch/`;
  const studyInsUID = JSON.stringify({ studyinstanceUID: stdyUID });
  const [documentList, setdocumentList] = useState([]);
  const [deletedElement, setDeletedElement] = useState('');

  // used to set error on api calls
  const [error, setError] = useState(false);
  const [errorDetails, setErrorDetails] = useState({});

  /*
  to fetch data from backend
  */

  const fetchData = () => {
    const idtoken = JSON.parse(
      sessionStorage.getItem(
        'oidc.user:' +
          window.config.oidc[0].authority +
          ':' +
          window.config.oidc[0].client_id
      )
    );
    const bearerToken = 'bearer ' + idtoken.id_token;
    axios
      .post(url, studyInsUID, { headers: { Authorization: bearerToken } })
      .then(res => {
        if (res.status === 200) {
          setError(false);
          setdocumentList(res.data);
        }
      })
      .catch(err => {
        console.error(err);
        if (err.response.status !== 400) {
          setError(true);
          setErrorDetails({
            description: err.response.data,
            title: err.response.statusText,
          });
        }
      });
  };
  useEffect(() => {
    fetchData();
  }, []);
  // fetchData();
  /*
  to download document
  */
  const downloadDoc = async index => {
    const idtoken = JSON.parse(
      sessionStorage.getItem(
        'oidc.user:' +
          window.config.oidc[0].authority +
          ':' +
          window.config.oidc[0].client_id
      )
    );
    const bearerToken = 'bearer ' + idtoken.id_token;
    const data = '';
    const downloadURL = `${BaseURL}docdownload/`;
    await axios
      .post(downloadURL, data, {
        params: {
          studyinstanceuid: stdyUID,
          filename: documentList[index].fileName,
        },
        headers: { Authorization: bearerToken },
        responseType: 'blob',
      })
      .then(res => {
        if (res.status === 200) {
          setError(false);
          const dfile = new Blob([res.data], {
            type: res.headers.get('Content-Type'),
          });
          const url = URL.createObjectURL(dfile);
          const a = document.createElement('a');
          a.href = url;
          a.download = documentList[index].fileName; 
    
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
    
          URL.revokeObjectURL(url);
        }
      })
      .catch(err => {
        console.error(err);
        if (err.response.status !== 400) {
          setError(true);
          setErrorDetails({
            description: err.response.data.toString(),
            title: err.response.statusText,
          });
        }
      });
  };

  /*
  to delete document
  */
  const deleteDoc = async fileName => {
    const deleteURL = `${BaseURL}docdelete/`;
    const idtoken = JSON.parse(
      sessionStorage.getItem(
        'oidc.user:' +
          window.config.oidc[0].authority +
          ':' +
          window.config.oidc[0].client_id
      )
    );
    const bearerToken = 'bearer ' + idtoken.id_token;
    await axios
      .delete(deleteURL, {
        params: {
          studyinstanceuid: stdyUID,
          filename: fileName,
        },
        headers: { Authorization: bearerToken },
      })
      .then(res => {
        if (res.status === 200) {
          setError(false);
          setDeletedElement(fileName);
          setTimeout(() => {
            setdocumentList(
              documentList.filter(document => document.fileName !== fileName)
            );
          }, 300);
        }
      })
      .catch(err => {
        console.error(err);
        setError(true);
        setErrorDetails({
          description: err.response.data.toString(),
          title: err.response.statusText,
        });
      });
  };

  if (error) {
    return (
      <>
        <ErrorPage
          error={errorDetails.error}
          title={errorDetails.title}
          description={errorDetails.description}
          // onRetry={() => {}}
        />
      </>
    );
  }

  return (
    <div className="container">
      <div className="sub-container">
        <label className="label-head">Select Document file </label>
        <br></br>
        <div className="file-input">
          <Uploader
            studyUID={stdyUID}
            fetch={fetchData}
            setError={setError}
            setErrorDetails={setErrorDetails}
          />
        </div>
        {documentList.length !== 0 && (
          <>
            <label className="label-head">Documents</label>
            <div className="document-list">
              <DocTable
                downloadDoc={downloadDoc}
                documents={documentList}
                deleteDoc={deleteDoc}
                deletedElement={deletedElement}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

AttachDocument.propTypes = {
  stdyUID: PropTypes.string,
};

export default AttachDocument;
