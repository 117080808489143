export const menuItems = [
  {
    title: 'Home',
    onclick: () => {
      alert('Home');
    },
  },
  {
    title: 'Clients',
    submenu: [
      {
        title: 'Addition',
        onclick: () => {
          alert('Addition');
        },
      },
      {
        title: 'Disable',
        onclick: () => {
          alert('Disable');
        },
      },
      {
        title: 'Permission',
        onclick: () => {
          alert('Permission');
        },
      },
      {
        title: 'Settings',
        submenu: [
          {
            title: '#ON PROGRESSION',
            onclick: () => {
              alert('On Progression');
            },
          },
        ],
      },
      {
        title: 'Login',
        submenu: [
          {
            title: 'Roles',
            onclick: () => {
              alert('Roles');
            },
          },
          {
            title: 'Users',
            onclick: () => {
              alert('Users');
            },
          },
        ],
      },
    ],
  },
  {
    title: 'About',
    // url: "/about",
    submenu: [
      {
        title: 'Who we are',
        onclick: () => {
          alert('click');
        },
      },
      {
        title: 'Our values',
        onclick: () => {
          alert('click');
        },
      },
    ],
  },
];
