import cornerstone from 'cornerstone-core';
import csTools from 'cornerstone-tools';
import { getEnabledElement } from './state';
import waitForTheImageToBeRendered from './utils/waitForTheImageToBeRendered';
const convertToVector3 = csTools.importInternal('util/convertToVector3');

function enableIntelliLink(element,sourceImagePoint)
{
    // Get current element target information
    const sourceElement = element;
    const sourceEnabledElement = cornerstone.getEnabledElement(
      sourceElement
    );
    const sourceImageId = sourceEnabledElement.image.imageId;
    const sourceImagePlane = cornerstone.metaData.get(
      'imagePlaneModule',
      sourceImageId
    );

    if (!sourceImagePlane) {
      return;
    }
    // Transfer this to a patientPoint given imagePlane metadata
    const patientPoint = imagePointToPatientPoint(
      sourceImagePoint,
      sourceImagePlane
    );
    cornerstone
    .getEnabledElements()
    .filter(e => e.uuid !== sourceEnabledElement.uuid)
    .forEach(async intelliLinkElement => {
      if (!intelliLinkElement.image)
        await waitForTheImageToBeRendered(intelliLinkElement.element);
         // Don't do anything if the target is the same as the source
      if (intelliLinkElement === sourceElement) {
        return;
      }
      let minDistance = Number.MAX_VALUE;
      let newImageIdIndex = -1;

      const stackToolDataSource = csTools.getToolState(intelliLinkElement.element, 'stack');

      if (stackToolDataSource === undefined) {
        return;
      }

      const stackData = stackToolDataSource.data[0];

      // Find within the element's stack the closest image plane to selected location
      stackData.imageIds.forEach(function(imageId, index) {
        const imagePlane = cornerstone.metaData.get(
          'imagePlaneModule',
          imageId
        );
        // Skip if the image plane is not ready
        if (
          !imagePlane ||
          !imagePlane.imagePositionPatient ||
          !imagePlane.rowCosines ||
          !imagePlane.columnCosines
        ) {
          return;
        }

        const imagePosition = convertToVector3(imagePlane.imagePositionPatient);
        const row = convertToVector3(imagePlane.rowCosines);
        const column = convertToVector3(imagePlane.columnCosines);
        const normal = column.clone().cross(row.clone());
        const distance = Math.abs(
          normal.clone().dot(imagePosition) - normal.clone().dot(patientPoint)
        );

        if (distance < minDistance) {
          minDistance = distance;
          newImageIdIndex = index;
        }
      });

      if (newImageIdIndex === stackData.currentImageIdIndex) {
        return;
      }
        // Switch the loaded image to the required image
        if (
          newImageIdIndex !== -1 &&
          stackData.imageIds[newImageIdIndex] !== undefined
        ) {
          const startLoadingHandler = csTools.loadHandlerManager.getStartLoadHandler(
            intelliLinkElement.element
          );
          const endLoadingHandler = csTools.loadHandlerManager.getEndLoadHandler(
            intelliLinkElement.element
          );
          const errorLoadingHandler = csTools.loadHandlerManager.getErrorLoadingHandler(
            intelliLinkElement.element
          );

          if (startLoadingHandler) {
            startLoadingHandler(intelliLinkElement.element);
          }

          let loader;

          if (stackData.preventCache === true) {
            loader = cornerstone.loadImage(
              stackData.imageIds[newImageIdIndex]
            );
          } else {
            loader = cornerstone.loadAndCacheImage(
              stackData.imageIds[newImageIdIndex]
            );
          }
          loader.then(
            function(image) {
              const viewport = cornerstone.getViewport(intelliLinkElement.element);

              stackData.currentImageIdIndex = newImageIdIndex;
              cornerstone.displayImage(intelliLinkElement.element, image, viewport);
              if (endLoadingHandler) {
                endLoadingHandler(intelliLinkElement.element, image);
              }
            },
            function(error) {
              const imageId = stackData.imageIds[newImageIdIndex];

              if (errorLoadingHandler) {
                errorLoadingHandler(intelliLinkElement.element, imageId, error);
              }
            }
          );
        }
    })
}

function imagePointToPatientPoint(imagePoint, imagePlane) {
  const rowCosines = convertToVector3(imagePlane.rowCosines);
  const columnCosines = convertToVector3(imagePlane.columnCosines);
  const imagePositionPatient = convertToVector3(
    imagePlane.imagePositionPatient
  );

  const x = rowCosines.clone().multiplyScalar(imagePoint.x);

  x.multiplyScalar(imagePlane.columnPixelSpacing);
  const y = columnCosines.clone().multiplyScalar(imagePoint.y);

  y.multiplyScalar(imagePlane.rowPixelSpacing);
  const patientPoint = x.add(y);

  patientPoint.add(imagePositionPatient);

  return patientPoint;
}
export default enableIntelliLink;
