import './UpdateContactStyle.css'
import axios from 'axios';
import React, { useEffect, useState } from 'react';

function UpdateContact({ updateContactOpen,patientID }) {
    const [email, setEmail] = useState('');
    const [contactNo, setContactNo] = useState('');
    const [emailError, setEmailError] = useState('');
    const [contactNoError, setContactNoError] = useState('');
    const [error, setError] = useState('');
    const BaseURL = window.config.servers.dicomWeb[0].apiRoot;

    const validEmail = new RegExp(
        '^[a-z0-9._:$!%-]+@[a-z0-9.-]+.[a-z]$'
    );

    const url = `${BaseURL}/api/fetchContactDetails/${patientID}/`;

    useEffect(() => {
        const fetchPatientDetails = async () => {
            var idtoken = JSON.parse(
                sessionStorage.getItem(
                  'oidc.user:' +
                    window.config.oidc[0].authority +
                    ':' +
                    window.config.oidc[0].client_id
                )
              );
            var bearerToken = 'bearer ' + idtoken.id_token;
            await fetch(url, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'Access-Control-Allow-Orgin': '*',
                  Authorization: bearerToken,
                },
                body: "",
                redirect: 'follow',
              })
            .then(response => response.json())
            .then(result => {
                if(result){
                    setEmail(result.emailID ? result.emailID : "");
                    setContactNo(result.contactNo ? result.contactNo : "");
                }
            })
            .catch(error => console.error('error', error));
        };
        fetchPatientDetails();
    },[patientID])

function handleEmailChange(event) {
    setEmail(event.target.value);
    setEmailError('');
    setError('');
}

function ValidateData(){
    if (email.trim().length === 0 || contactNo.length === 0) {
        setError("Field is empty");
        return false;
    }
    else if (!validEmail.test(email)) {
        setEmailError("Please enter a valid email.");
        setError(true);
        return false;
    }
    else if (contactNo.length < 10) {
        setContactNoError("Contact number must be at least 10 digits.");
        setError(true);
        return false;
    }
    else {
        setError('');
        return true;
    }
}

function handleContactNoChange(event) {
    const value = event.target.value;
    if (/^\d*$/.test(value) && value.length <= 20) {
        setContactNo(value);
    }
    setContactNoError('');
    setError('');
}

function handleSubmit() {
    const url = `${BaseURL}/api/updatecontacts`;

    if(ValidateData()){
        var idtoken = JSON.parse(
            sessionStorage.getItem(
              'oidc.user:' +
                window.config.oidc[0].authority +
                ':' +
                window.config.oidc[0].client_id
            )
          );
          var bearerToken = 'bearer ' + idtoken.id_token;
        fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Orgin': '*',
              Authorization: bearerToken,
            },
            body: JSON.stringify({
                patientID: patientID,
                emailID: email,
                contactNo:contactNo
            }),
          })
          .then(response => {
            if (response.status === 200) {
              setError(false);
              updateContactOpen(false);
              var x = document.getElementById('snackbar');
              x.className = 'show success';
              x.innerHTML = 'Saved';
              setTimeout(function() {
                x.className = x.className.replace('show success', '');
              }, 3000);
            }})
        .then(result => {
            if(result){
                console.log(result);
            }
        })
        .catch(error => console.error('error', error));
    }
}

return (
    <>
        <div>
            <form className='contact-form' >
                <table className="contact-table">
                    <tr>
                        <th>Email ID <span className='required'> * </span> </th>
                        <td>
                            <input
                                className='input-text'
                                value={email}
                                type="email"
                                onChange={(event) => handleEmailChange(event)}
                                required
                            />
                            {emailError && <p className='error'>{emailError}</p>}
                        </td>
                    </tr>

                    <tr>
                        <th>Mobile No <span className='required'> * </span> </th>
                        <td>
                            <input
                                className='textbox'
                                type="text"
                                value={contactNo}
                                onChange={(event) => handleContactNoChange(event)}
                                required
                            />
                            {contactNoError && <p className='error'>{contactNoError}</p>}
                            {error && <p className='error'>{error}</p>}
                        </td>
                    </tr>
                </table>

                <button className="submit-button" type="button" onClick={handleSubmit}>
                    Submit
                </button>
            </form>
        </div>
    </>
);
}
export default UpdateContact;
