import React, { useEffect, useState } from 'react';
import { Icon, Loader } from '@ohif/ui';
import axios from 'axios';
import PropTypes from 'prop-types';
import './ReportPdfFrame.styl';

function ReportPdfFrame({
  AccessionNumber,
  StudyInstanceUID,
  className = '',
  downloadURL = { downloadURL },
}) {
  const [fileData, setFileData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    fetchDoc();
  }, [AccessionNumber, StudyInstanceUID]);

  const fetchDoc = async () => {
    setLoading(true);
    const idtoken = JSON.parse(
      sessionStorage.getItem(
        'oidc.user:' +
          window.config.oidc[0].authority +
          ':' +
          window.config.oidc[0].client_id
      )
    );
    const params = {
      // studyinstanceuid: '1.3.6.1.4.1.5962.1.2.33490.1166546115.14677',
      // filename: 'Doc_2024-01-10T11383317',
    };
    if (StudyInstanceUID) {
      params.studyinstanceuid = StudyInstanceUID;
    } else if (AccessionNumber) {
      params.accessionnumber = AccessionNumber;
    }
    const bearerToken = 'bearer ' + idtoken.id_token;
    const data = '';
    await axios
      .post(downloadURL, data, {
        params: params,
        headers: { Authorization: bearerToken },
        responseType: 'blob',
      })
      .then(res => {
        if (res.status === 200) {
          setError(false);
          const dfile = new Blob([res.data], {
            type: res.headers.get('Content-Type'),
          });
          setFileData(URL.createObjectURL(dfile));
        } else {
          setFileData(null);
        }
      })
      .catch(err => {
        console.error(err);
        if (err.response.status !== 400) {
          setError(true);
        }
      });
    setLoading(false);
  };

  return (
    <>
      {loading ? (
        <div className={'PdfViewer ' + className}>
          <Loader />
        </div>
      ) : fileData ? (
        <iframe
          title="PdfViewer"
          src={fileData}
          width="100%"
          className={'PdfViewer ' + className}
          height="200"
        />
      ) : (
        <div
          className={'PdfViewer ' + className}
          style={{ color: error ? 'red' : '#fff' }}
        >
          <Icon name="exclamation-circle" />
          {error ? (
            <h5>Error while opening the document !</h5>
          ) : (
            <h5>Report Not Found</h5>
          )}
        </div>
      )}
    </>
  );
}

ReportPdfFrame.propTypes = {
  AccessionNumber: PropTypes.string,
  StudyInstanceUID: PropTypes.string,
  downloadURL: PropTypes.string,
  className: PropTypes.string,
};

export default ReportPdfFrame;
