import React, { useEffect } from 'react';
import './Uploader.css';
import { useState, useRef } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Icon } from '@ohif/ui';

const Uploader = ({ studyUID, fetch, setError, setErrorDetails }) => {
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [uploadedDocDes, setUploadedDocDes] = useState('');
  const [uploadedMessage, setUploadedMessage] = useState({
    message: '',
    type: 'info',
  });
  const extensionArray = ['pdf','docx','doc','jpeg','jpg']
  const [uploadedFile, setUploadedFile] = useState(false);
  const inputRef = useRef('');
  const BaseURL = window.config.servers.dicomWeb[0].wadoRoot;
  const defaultUploadedFileSize = 10;
  const DesChangeHandler = e => {
    setUploadedDocDes(e.target.value);
  };

  const FileChangeHandler = e => {
    const fileSize = inputRef.current.files[0].size / (1024 * 1024);
    if (fileSize > defaultUploadedFileSize) {
      setFile(null);
      setUploadedMessage({
        message: 'File Size Exceeds (Max size 10mb)',
        type: 'warning',
      });
      setTimeout(() => {
        setUploadedMessage({
          message: '',
          type: '',
        });
      }, 5000);
    } else {
      setFile(inputRef.current.files[0]);
      setFileName(inputRef.current.files[0].name);
    }
  };

  const getExtension = filename => {
    return filename.split('.').pop();
  };

  const submitHandler = event => {
    event.preventDefault();

    const idtoken = JSON.parse(
      sessionStorage.getItem(
        'oidc.user:' +
          window.config.oidc[0].authority +
          ':' +
          window.config.oidc[0].client_id
      )
    );
    const bearerToken = 'bearer ' + idtoken.id_token;
    if (file === null) return;
    setUploadedFile(true);
    if (!extensionArray.includes(getExtension(file.name.toLowerCase())) ) {
      return;
    }
    const url = `${BaseURL}docupload/`;
    const data = new FormData();
    data.append('file', file);
    data.append('fileName', file.name);
    var fileExtension = fileName.substr(fileName.lastIndexOf('.') );
    axios
      .post(url, data, {
        params: {
          studyinstanceuid: studyUID,
          documentdescription: uploadedDocDes,
          fileextension:fileExtension,
        },
        headers: {
          Authorization: bearerToken,
        },
      })
      .then(response => {
        if (response.status === 200) {
          setError(false);
          setUploadedFile(false);
          cancelHandler();
          setUploadedMessage({
            message: 'Uploaded Successfully',
            type: 'success',
          });
          fetch();
          setTimeout(() => {
            setUploadedMessage({
              message: '',
              type: '',
            });
          }, 1000);
        }
      })
      .catch(err => {
        console.error(err);
        setError(true);
        setErrorDetails({
          description: err.response.data,
          title: err.response.statusText,
        });
      });
  };

  const cancelHandler = () => {
    setFileName('');
    setUploadedDocDes('');
    setFile(null);
  };

  useEffect(() => {
    if (file && !extensionArray.includes(getExtension(file.name.toLowerCase()))) {
      setFile(null);
      setUploadedMessage({
        message: '* Only '+ extensionArray.join(',') +' files allowed',
        type: 'warning',
      });
      setTimeout(() => {
        setUploadedMessage({
          message: '',
          type: '',
        });
      }, 5000);
    }
  }, [file]);

  return (
    <div className="main">
      <form
        className="selct-files"
        onClick={() => document.querySelector('.input-field').click()}
      >
        <h5
          className="input-head"
          style={{
            color:
              uploadedMessage.message !== ''
                ? uploadedMessage.type === 'warning'
                  ? '#ffbf00'
                  : '#00FF90'
                : '#ffffff',
          }}
        >
          {file
            ? fileName
            : uploadedMessage.message !== ''
            ? uploadedMessage.message
            : 'Select files.. ( Max size 10mb )'}
        </h5>
        <input
          type="file"
          ref={inputRef}
          onChange={FileChangeHandler}
          accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/jpeg"
          name=""
          id=""
          value={''}
          className="input-field"
        />
      </form>
      <input
        className="description-input ui-input"
        maxLength={50}
        name="file"
        value={uploadedDocDes}
        onChange={DesChangeHandler}
        type="text"
        placeholder="Doc Description ( Max 50 Char )"
      ></input>
      <div className="buttons-container">
        <div className="attach-btn-container">
          <button
            className="attach-btn attach-btn-upload"
            disabled={file === null || uploadedFile}
            onClick={e => {
              if (!uploadedFile) submitHandler(e);
            }}
            title="Upload"
          >
            <Icon name="upload" />
          </button>
        </div>
        <div className="attach-btn-container">
          <button
            className="attach-btn attach-btn-cancel"
            disabled={file === null}
            onClick={cancelHandler}
            title="Cancel"
          >
            <Icon name="close" />
          </button>
        </div>
      </div>
    </div>
  );
};

Uploader.propTypes = {
  studyUID: PropTypes.string,
  fetch: PropTypes.func,
  setError: PropTypes.func,
  setErrorDetails: PropTypes.func,
};

export default Uploader;
