import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './SearchReport.css';
import { Icon, useDebounce } from '@ohif/ui';
import ReportPdfFrame from '../../../../viewer/src/ReportPdfFrame/ReportPdfFrame';

function SearchReport(props) {
  const [reportList, setReportList] = useState([]);
  const [selectedReport, setSelectedReport] = useState();
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchValue = useDebounce(searchValue, 250);
  useEffect(() => {
    if (searchValue.trim().length > 0) fetchReportList();
    else {
      setReportList([]);
      setSelectedReport();
    }
  }, [debouncedSearchValue]);

  const fetchReportList = async () => {
    var headerData = new Headers();
    var idtoken = JSON.parse(
      sessionStorage.getItem(
        'oidc.user:' +
          window.config.oidc[0].authority +
          ':' +
          window.config.oidc[0].client_id
      )
    );
    var bearerToken = 'bearer ' + idtoken.id_token;
    headerData.append('Content-Type', 'application/json');
    headerData.append('Access-Control-Allow-Origin', '*');
    headerData.append('Authorization', bearerToken);

    var requestOptions = {
      method: 'POST',
      headers: headerData,
      body: JSON.stringify({ Content: searchValue.trim() }),
      redirect: 'follow',
    };

    await fetch(
      `${window.config.servers.dicomWeb[0].apiRoot}/api/SearchReportContent`,
      requestOptions
    )
      .then(response => response.json())
      .then(result => {
        if (result.length > 0) {
          result.map(item => {
            switch (item.reportStatus) {
              case '1':
                item.reportStatus = 'Drafted';
                break;
              case '2':
                item.reportStatus = 'Preliminary';
                break;
              case '3':
                item.reportStatus = 'Finalized';
                break;
              default:
                break;
            }
            item.patientName =
            item.patientName === undefined
              ? ''
              : item.patientName.replace(/\^/g, ' ');
          });
        }
        setReportList(result);
        if (
          selectedReport &&
          (result.length === 0 ||
            !result.find(item => item.studyUid === selectedReport.studyUid))
        )
          setSelectedReport();
      })
      .catch(error => {
        console.error(error);
      });
  };

  return (
    <div className="search-report-body">
      <div className="search-report-list">
        <div className="search-report-searchBar">
          <div className="searchbar">
            <button>
              <Icon name="search" />
            </button>
            <input
              type="search"
              name="search-report_textBox"
              id="search-report_textBox"
              value={searchValue}
              onChange={e => {
                e.preventDefault();
                setSearchValue(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="search-report-tableList">
          <table>
            <thead>
              <tr>
                <th>Accession No</th>
                <th>Patient Id</th>
                <th>Patient Name</th>
                <th>Report Status</th>
              </tr>
            </thead>
            {reportList.length > 0 ? (
              <tbody>
                {reportList.map((reportDetails, index) => (
                  <tr
                    className={
                      selectedReport &&
                      selectedReport.studyUid === reportDetails.studyUid
                        ? 'selected'
                        : 'doc-element'
                    }
                    key={index}
                    onClick={() => {
                      setSelectedReport(reportDetails);
                    }}
                  >
                    <td title={reportDetails.accessionNo}>
                      {reportDetails.accessionNo}
                    </td>
                    <td title={reportDetails.patientId}>
                      {reportDetails.patientId}
                    </td>
                    <td title={reportDetails.patientName}>
                      {reportDetails.patientName}
                    </td>
                    <td title={reportDetails.reportStatus}>
                      {reportDetails.reportStatus}
                    </td>
                  </tr>
                ))}
              </tbody>
            ) : (
              <div className="no-result">No Results</div>
            )}
          </table>
        </div>
      </div>
      <div className="search-report-view">
        <div className="report-pdf-viewer" title="PdfViewer">
          {selectedReport ? (
            <ReportPdfFrame
              AccessionNumber={selectedReport.accessionNo}
              StudyInstanceUID={selectedReport.studyUid}
              className="report-pdf-viewer"
              downloadURL={`${window.config.servers.dicomWeb[0].apiRoot}/api/ViewReportPdf/`}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}

SearchReport.propTypes = {
  studies: PropTypes.array,
};
export default SearchReport;
