import './StudyList.styl';
import './StudyList.css';
import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { StudyListLoadingText } from './StudyListLoadingText.js';
import { useTranslation } from 'react-i18next';
import { Icon } from '@ohif/ui';
import TableSearchFilter from './TableSearchFilter';
import RightClickMenu from './RightClickMenu';
import StudyInfoContent from './StudyInfoContent';
import UpdateContact from './UpdateContact.js';
import AttachDocument from './AttachDocument';
import AssignToUserContent from './AssignToUserContent';
import MenuItems from '../../../../viewer/src/components/DropedownComponent/DropedownComponentMenuItems';
import Snackbar from './Snackbar';
import CommentsContainer from './CommentsContainer';
import { isMobile } from 'react-device-detect';
import axios from 'axios';
import printJS from 'print-js';
import UiModal from '../../../../viewer/src/components/UiModal/UiModal.js';
import WarningModal from '../../../../viewer/src/components/WarningModal/WarningModal.js';
import CusModal from '../../../../viewer/src/studylist/CusModal.js';
import ConfirmationPopup from '../confirmation-popup/ConfirmationPopup.js';
import ViewReportPdf from '../view-reportpdf/ViewReportPdf.js';
import moment from 'moment';

// const getContentFromUseMediaValue = (
//   displaySize,
//   contentArrayMap,
//   defaultContent
// ) => {
//   const content =
//     displaySize in contentArrayMap
//       ? contentArrayMap[displaySize]
//       : defaultContent;

//   return content;
// };

/**
 *
 *
 * @param {*} props
 * @returns
 */
function StudyList(props) {
  const {
    CustomDateFilter,
    isSideBarExpanded,
    isLoading,
    hasError,
    tableMeta,
    studies,
    sort,
    onSort: handleSort,
    filterValues,
    onFilterChange: handleFilterChange,
    onSelectItem: handleSelectItem,
    studyListDateFilterNumDays,
    displaySize,
    updateTableMeta,
    updateTableMetaOrder,
    checkedRowData,
    updateCheckedRowData,
    ChangeTableMetaColumnsize,
    inputstyle,
    checkUncheckAll,
    setCheckUncheckAll,
    centerArray,
    userInfo,
    SetSelectedPriority,
    selectedPriority,
    PriorityOptions,
    reportStatusArry,
    reportStatuss,
    SetReportStatus,
    tempCenterArray,
    center,
    SetCenter,
    modalitiesArray,
    modality,
    setModality,
    tempModalitie,
    user,
    userId,
    studyLimitExceed,
    licenseDetails
  } = props;
  const { t, ready: translationsAreReady } = useTranslation('StudyList');
  const [clickedColumn, setClickedColumn] = useState(false);
  const [pointsColumn, setPointsColumn] = useState({
    x: 0,
    y: 0,
  });
  const [column, setColumn] = useState({});
  const [clicked, setClicked] = useState(false);
  const [points, setPoints] = useState({
    x: 0,
    y: 0,
  });

  // for drag and dropping the columns
  // which column is dragging
  const [dragOver, setDragOver] = useState('');

  // dropping column
  const [colIdx, setcolIdx] = useState({});

  // dragging setting column
  const handleDragStart = (field, e) => {
    setcolIdx(field);
    setClickedColumn(false);
  };

  // drag over another column and set the value of the column below the dragging column
  const handleDragOver = e => e.preventDefault();
  const handleDragEnter = (field, e) => {
    e.preventDefault();
    const { fieldName } = field;
    setDragOver(fieldName);
  };

  // dropping the dragged column to the right of below column
  const handleOnDrop = e => {
    e.preventDefault();
    const field = tableMeta.find(item => item.fieldName === dragOver);
    const tempArray = [colIdx];
    updateTableMetaOrder(field, tempArray, 'show');
    setDragOver('');
  };

  const [ref, setRef] = useState(useRef(null));
  // const baseReportURL = window.config.servers.dicomWeb[0].reportRoot;

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        handleClick(false);
      }
    }
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [filterValues, ref]);
  useEffect(() => {
    if (
      filterValues.studyDateFrom === null &&
      filterValues.studyDateTo === null
    ) {
      const defaultNumDays =
        window.config.servers.dicomWeb[0].defaultStudyFetchDays;
      if (defaultNumDays && defaultNumDays !== 0) {
        handleFilterChange(
          'studyDateFrom',
          moment().subtract(defaultNumDays - 1, 'day')
        );
        handleFilterChange('studyDateTo', moment());
      }
    }
  }, []);

  const [isSharingEnabled,setIsSharingEnabled] = useState({}) 
  useEffect(()=>{
    fetchSharingConfiguration()
  },[])

  const fetchSharingConfiguration = async () => {
    const idtoken = JSON.parse(
      sessionStorage.getItem(
        'oidc.user:' +
          window.config.oidc[0].authority +
          ':' +
          window.config.oidc[0].client_id
      )
    );
    const bearerToken = 'bearer ' + idtoken.id_token;
    const url = `${window.config.servers.dicomWeb[0].apiRoot}/api/fetchSharingConfiguration/`;
    await fetch(
        url,
        {
      headers: { Authorization: bearerToken },
        }
      )
      .then(response => response.json())
      .then(result => {
        setIsSharingEnabled({
          isEmailEnabled : result.enabledEmailSharing,
          isSmsEnabled : result.enabledSmsSharing,
          isWhatsappEnabled : result.enabledWhatsappSharing })
      })
      .catch(err => {
        console.error(err);
      });
  }

  const handleClick = clickedValue => {
    setClickedColumn(clickedValue);
  };
  const handleRef = refValue => {
    setRef(refValue);
  };

  const handleContextMenuValues = (clicked, points, column) => {
    setClickedColumn(clicked);
    if (clicked) {
      setClicked(false);
    }
    setPointsColumn(points);
    setColumn(column);
  };
  // const totalSize = tableMeta
  //   .map(field => field.size)
  //   .reduce((prev, next) => prev + next);
  const [row, setRow] = useState('');
  const updateRow = data => {
    setRow(data);
  };
  const updateClickedColumn = data => {
    setClickedColumn(data);
  };
  const updateClicked = data => {
    setClicked(data);
  };
  const updatePoints = data => {
    setPoints(data);
  };

  // function check and uncheck all the checkboxes when the select all checkbox is checked or unchecked
  const checkUncheck = () => {
    var getAllCheckboxes = document.getElementsByName('checkRow');
    for (let i = 0; i < getAllCheckboxes.length; i++) {
      getAllCheckboxes[i].checked = !checkUncheckAll;
    }
    if (!checkUncheckAll) {
      updateCheckedRowData(studies);
    } else {
      updateCheckedRowData([]);
    }
    setCheckUncheckAll(!checkUncheckAll);
  };

  const [userConfirmation, setUserConfirmation] = useState(false);
  const [studyData, setStudyData] = useState({});
  const updateConfirmationOpen = item => {
    setUserConfirmation(item);
    setStudyData({});
  };

  const handleClose = () => {
    setUserConfirmation(false);
    setStudyData({});
  };
  const handleSave = () => {
    handleSelectItem(studyData);
    setUserConfirmation(false);
    setStudyData({});
  };
  const UserConfirmation = () => {
    var assignedUsers = '';
    if (studyData.assignedUserArray) {
      for (let index = 0; index < studyData.assignedUserArray.length; index++) {
        assignedUsers += studyData.assignedUserArray[index].userName + ', ';
      }
      assignedUsers = assignedUsers.substring(0, assignedUsers.length - 2);
    }
    return (
      <>
        <div className="user-confirm">
          <div className="user-confirm-body">
            <p>
              {'This study is assigned to other user(s): '}
              <strong>
                {assignedUsers}
                {', '}
              </strong>{' '}
            </p>
            <p>{'Do you want to proceed ?'}</p>
          </div>
        </div>
      </>
    );
  };

  return translationsAreReady ? (
    <>
      <table className="table table--striped table--hoverable">
        {/* <colgroup>
        {tableMeta.map((field, i) => {
          if (field.show) {
            const size = field.size;
            // const percentWidth = (size / totalSize) * 100.0;
            return <col key={i} style={{ width: `${size}px` }} />;
          }
        })}
      </colgroup> */}
        <thead className="table-head">
          <tr className="filters">
            <TableSearchFilter
              CustomDateFilter={CustomDateFilter}
              inputstyle={inputstyle}
              ChangeTableMetaColumnsize={ChangeTableMetaColumnsize}
              meta={tableMeta}
              studies={studies}
              isLoading={isLoading}
              values={filterValues}
              onSort={handleSort}
              onValueChange={handleFilterChange}
              sortFieldName={sort.fieldName}
              sortDirection={sort.direction}
              studyListDateFilterNumDays={studyListDateFilterNumDays}
              handleContextMenuValues={handleContextMenuValues}
              checkUncheck={checkUncheck}
              checkUncheckAll={checkUncheckAll}
              checkedRowData={checkedRowData}
              centerArray={centerArray}
              handleDragStart={handleDragStart}
              handleDragOver={handleDragOver}
              handleOnDrop={handleOnDrop}
              handleDragEnter={handleDragEnter}
              dragOver={dragOver}
              selectedPriority={selectedPriority}
              SetSelectedPriority={SetSelectedPriority}
              PriorityOptions={PriorityOptions}
              reportStatusArry={reportStatusArry}
              reportStatuss={reportStatuss}
              SetReportStatus={SetReportStatus}
              tempCenterArray={tempCenterArray}
              SetCenter={SetCenter}
              center={center}
              modality={modality}
              setModality={setModality}
              handleClick={handleClick}
              modalitiesArray={modalitiesArray}
              tempModalitie={tempModalitie}
            />
            <td>
              <Snackbar />
            </td>
          </tr>
          {clickedColumn && (
            <RightClickMenu
              top={pointsColumn.y}
              left={pointsColumn.x}
              isSideBarExpanded={isSideBarExpanded}
              field={column}
              updateTableMeta={updateTableMeta}
              updateTableMetaOrder={updateTableMetaOrder}
              handleClick={handleClick}
              handleRef={handleRef}
              tableMeta={tableMeta}
            />
          )}
        </thead>
        <tbody className="table-body" data-cy="study-list-results">
          {/* I'm not in love with this approach, but it's the quickest way for now
           *
           * - Display different content based on loading, empty, results state
           *
           * This is not ideal because it create a jump in focus. For loading especially,
           * We should keep our current results visible while we load the new ones.
           */}
          {/* LOADING */}
          {isLoading && (
            <div className="no-hover">
              {/* <div colSpan={tableMeta.length}> */}
              <StudyListLoadingText />
              {/* </div> */}
            </div>
          )}
          {!isLoading && hasError && (
            <div className="no-hover">
              {/* <td colSpan={tableMeta.length}> */}
              <div className="notFound">
                {t('There was an error fetching studies')}
              </div>
              {/* </td> */}
            </div>
          )}
          {/* EMPTY */}
          {!isLoading &&
            studies.map((study, index) => (
              <TableRow
                checkedRowData={checkedRowData}
                updateCheckedRowData={updateCheckedRowData}
                key={`${study.StudyInstanceUID}-${index}`}
                rowKey={`${study.StudyInstanceUID}-${index}`}
                onClick={async (study) => {
                  var userarray = [];
                  var headerData = new Headers();
                  var idtoken = JSON.parse(
                    sessionStorage.getItem(
                      'oidc.user:' +
                        window.config.oidc[0].authority +
                        ':' +
                        window.config.oidc[0].client_id
                    )
                  );
                  var bearerToken = 'bearer ' + idtoken.id_token;
                  headerData.append('Content-Type', 'application/json');
                  headerData.append('Access-Control-Allow-Origin', '*');
                  headerData.append('Authorization', bearerToken);

                  var request = {
                    method: 'POST',
                    headers: headerData,
                    body: '',
                    redirect: 'follow',
                  };

                  await fetch(
                    `${window.config.servers.dicomWeb[0].apiRoot}/api/assignuser/fetch/${study.StudyInstanceUID}/`,
                    request
                  )
                    .then(response => response.json())
                    .then(result => (userarray = result))
                    .catch(error => console.error('error', error));
                  const assignedUserArray = userarray.filter(
                    user => user.isAssigned === 'Y'
                  );
                  if (
                    assignedUserArray.length === 0 ||
                    assignedUserArray.find(
                      userData => userData.userId === userId
                    )
                  ) {
                    handleSelectItem(study);
                  } else {
                    setUserConfirmation(true);
                    setStudyData({
                      StudyInstanceUID: study.StudyInstanceUID,
                      PatientID: study.PatientID,
                      assignedUserArray: assignedUserArray,
                    });
                  }
                }}
                study={study}
                displaySize={displaySize}
                tableMeta={tableMeta}
                updateRow={updateRow}
                row={row}
                clickedColumn={clickedColumn}
                updateClickedColumn={updateClickedColumn}
                clicked={clicked}
                updateClicked={updateClicked}
                points={points}
                updatePoints={updatePoints}
                setCheckUncheckAll={setCheckUncheckAll}
                dragOver={dragOver}
                centerArray={centerArray}
                userInfo={userInfo}
                handleFilterChange={handleFilterChange}
                user={user}
                userId={userId}
                isSideBarExpanded={isSideBarExpanded}
                isSharingEnabled={isSharingEnabled}
                isEnablePacs={licenseDetails.isEnablePacs === 1}
              />
            ))}
          {userConfirmation && (
            <CusModal>
              <UiModal
                modalSize={'sm'}
                updateisOpen={updateConfirmationOpen}
                title="Confirm User"
                footerVisibility={true}
                closeBtnTitle="No"
                saveBtnTitle="Yes"
                onClose={handleClose}
                onSave={handleSave}
              >
                <UserConfirmation />
              </UiModal>
            </CusModal>
          )}
        </tbody>
      </table>
      {!isLoading && !studies.length && !studyLimitExceed && (
        <div className="no-result-text">
          {/* <div colSpan={tableMeta.length}> */}
          <div className="notFound">{t('No matching results')}</div>
          {/* </div> */}
        </div>
      )}
      {!isLoading && !studies.length && studyLimitExceed && (
        <div className="no-result-text">
          {/* <div colSpan={tableMeta.length}> */}
          <div className="notFound">
            {t('Study fetch limit exceeded, Please filter your search.')}
          </div>
          {/* </div> */}
        </div>
      )}
    </>
  ) : null;
}

StudyList.propTypes = {
  studyLimitExceed: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  hasError: PropTypes.bool.isRequired,
  studies: PropTypes.array.isRequired,
  onSelectItem: PropTypes.func.isRequired,
  ChangeTableMetaColumnsize: PropTypes.func,
  inputstyle: PropTypes.string,
  centerArray: PropTypes.array,
  // ~~ SORT
  sort: PropTypes.shape({
    fieldName: PropTypes.string,
    direction: PropTypes.oneOf(['desc', 'asc', null]),
  }).isRequired,
  onSort: PropTypes.func.isRequired,
  // ~~ FILTERS
  filterValues: PropTypes.shape({
    PatientName: PropTypes.string.isRequired,
    PatientID: PropTypes.string.isRequired,
    AccessionNumber: PropTypes.string.isRequired,
    StudyDate: PropTypes.string.isRequired,
    modalities: PropTypes.string.isRequired,
    StudyDescription: PropTypes.string.isRequired,
    patientNameOrId: PropTypes.string.isRequired,
    accessionOrModalityOrDescription: PropTypes.string.isRequired,
    allFields: PropTypes.string.isRequired,
    studyDateTo: PropTypes.any,
    studyDateFrom: PropTypes.any,
    referringPhysicianName: PropTypes.string.isRequired,
  }).isRequired,
  onFilterChange: PropTypes.func.isRequired,
  studyListDateFilterNumDays: PropTypes.number,
  displaySize: PropTypes.string,
  tableMeta: PropTypes.array,
  updateTableMeta: PropTypes.func,
  updateTableMetaOrder: PropTypes.func,
  clickedColumn: PropTypes.bool,
  updateClickedColumn: PropTypes.func,
  clicked: PropTypes.bool,
  updateClicked: PropTypes.func,
  points: PropTypes.object,
  updatePoints: PropTypes.func,
  checkedRowData: PropTypes.array,
  updateCheckedRowData: PropTypes.func,
  checkUncheckAll: PropTypes.bool,
  setCheckUncheckAll: PropTypes.func,
  isSideBarExpanded: PropTypes.bool,
  CustomDateFilter: PropTypes.func,
  userInfo: PropTypes.object,
  selectedPriority: PropTypes.array,
  SetSelectedPriority: PropTypes.func,
  PriorityOptions: PropTypes.array,
  reportStatuss: PropTypes.array,
  SetReportStatus: PropTypes.func,
  reportStatusArry: PropTypes.array,
  tempCenterArray: PropTypes.array,
  center: PropTypes.array,
  modality: PropTypes.array,
  setModality: PropTypes.func,
  SetCenter: PropTypes.func,
  modalitiesArray: PropTypes.array,
  tempModalitie: PropTypes.array,
  user: PropTypes.object,
  isSharingEnabled:PropTypes.object,
  isEnablePacs:PropTypes.bool
};

StudyList.defaultProps = {};
function TableRow(props) {
  const {
    study,
    rowKey: tableRowKey,
    // isHighlighted,
    onClick: handleClick,
    tableMeta,
    updateRow,
    row,
    clickedColumn,
    updateClickedColumn,
    clicked,
    updateClicked,
    points,
    updatePoints,
    checkedRowData,
    updateCheckedRowData,
    setCheckUncheckAll,
    dragOver,
    centerArray,
    userInfo,
    handleFilterChange,
    user,
    userId,
    isSideBarExpanded,
    isSharingEnabled,
    isEnablePacs,
    // displaySize,
  } = props;

  const { t } = useTranslation('StudyList');
  const [studyinfoOpen, setStudyinfoOpen] = useState(false);
  const [assigntoOpen, setAssigntoOpen] = useState(false);
  const [isCommentsOpen, setIsCommentsOpen] = useState(false);
  const [isUpdateContactOpen, setIsUpdateContactOpen] = useState(false);
  const [isViewReportPdfOpen, setIsViewReportPdfOpen] = useState(false);
  const [noReportContent, setNoReportContent] = useState({
    isVisible: false,
    message: '',
  });
  const [isSendPopupOpen, setIsSendPopupOpen] = useState(false);
  const [isSaveBtnDisable, setIsSaveBtnDisable] = useState(false);

  const [toShowOrNotToViewStudy, setToShowOrNotToViewStudy] = useState('N');
  const [toShowOrNotDeleteStudy, setToShowOrNotDeleteStudy] = useState('N');
  const [toShowOrNotTheReport, setToShowOrNotTheReport] = useState('N');
  const [toShowOrNotToAssignToUser, setToShowOrNotToAssignToUser] = useState(
    'N'
  );
  const [
    toShowOrNotSendStudyOrReport,
    setToShowOrNotSendStudyOrReport,
  ] = useState('N');
  const [toShowOrNotToSetPriority, setToShowOrNotToSetPriority] = useState('N');
  const [toShowOrNotClinicalNotes, setToShowOrNotClinicalNotes] = useState('N');
  const [toShowOrNotPrint, setToShowOrNotPrint] = useState('N');
  const [toShowOrNotDocument, setToShowOrNotDocument] = useState('N');
  const BaseURL = window.config.servers.dicomWeb[0].wadoRoot;
  const [isPrintReportOpen, setIsPrintReportOpen] = useState(false);
  const [blobData, setBlobData] = useState('');
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [viewReportConfirmation, setViewReportConfirmation] = useState(false);

  const [sendStudyReportData, setSendStudyReportData] = useState({
    inputType: '',
    emailId: '',
    phoneNo: '',
    sendThrough: '',
    sendTypeId: '',
    sendToPatient: true,
  });

  const handleSendStudyReportData = values => {
    setSendStudyReportData(state => {
      return {
        ...state,
        ...values,
      };
    });
  };
  // var viewStudy = useRef('');
  // var viewReport = useRef('');
  // var assignStudy = useRef('');
  // var setPriority = useRef('');
  var center = centerArray.find(item => item.centerName === study.ffincenterid);

 

  useEffect(() => {
    async function fetchaccess() {
      var isCompletedHL7Study = !(
        study.ffinTransferMode === '1' && study.ffinStudyStatus === '10'
      );
      if (isCompletedHL7Study) {
        setToShowOrNotDocument('Y');
        setToShowOrNotClinicalNotes('Y');
      }
      if (
        ((await userInfo.HasCenterPrivilege('C_VIEW_STUDY', center.centerId)) ||
          ((await userInfo.HasSystemPrivilege('SC_VIEW_STUDY')) &&
            (await userInfo.HasAllCentersAssigned))) &&
        isCompletedHL7Study
      )
        setToShowOrNotToViewStudy('Y');
      if (
        ((await userInfo.HasCenterPrivilege(
          'C_DELETE_STUDY_SERIES',
          center.centerId
        )) ||
          ((await userInfo.HasSystemPrivilege('SC_DELETE_STUDY_SERIES')) &&
            (await userInfo.HasAllCentersAssigned))) &&
        isCompletedHL7Study
      )
        setToShowOrNotDeleteStudy('Y');
      if (
        ((await userInfo.HasCenterPrivilege(
          'C_PRINT_REPORT',
          center.centerId
        )) ||
          ((await userInfo.HasSystemPrivilege('SC_PRINT_REPORT')) &&
            (await userInfo.HasAllCentersAssigned))) &&
        isCompletedHL7Study
      )
        setToShowOrNotPrint('Y');
      if (
        ((await userInfo.HasCenterPrivilege(
          'C_ACCESS_REPORT',
          center.centerId
        )) ||
          ((await userInfo.HasSystemPrivilege('SC_ACCESS_REPORT')) &&
            (await userInfo.HasAllCentersAssigned))) &&
        isCompletedHL7Study
      )
      if(
          (study.ffinreportstatus !== '0' && study.ffinreportstatus !== '1' && study.ffinreportstatus !== '4') || 
          ((await userInfo.HasCenterPrivilege(
            'C_PREPARE_REPORT',
            center.centerId
          )) ||
            ((await userInfo.HasSystemPrivilege('SC_PREPARE_REPORT')) &&
              (await userInfo.HasAllCentersAssigned)))
        )
          setToShowOrNotTheReport('Y');
      if (
        ((await userInfo.HasCenterPrivilege(
          'C_ASSIGN_STUDY',
          center.centerId
        )) ||
          ((await userInfo.HasSystemPrivilege('SC_ASSIGN_STUDY')) &&
            (await userInfo.HasAllCentersAssigned))) &&
        isCompletedHL7Study
      )
        setToShowOrNotToAssignToUser('Y');
      if (
        ((await userInfo.HasCenterPrivilege(
          'C_SET_PRIORITY',
          center.centerId
        )) ||
          ((await userInfo.HasSystemPrivilege('SC_SET_PRIORITY')) &&
            (await userInfo.HasAllCentersAssigned))) &&
        isCompletedHL7Study
      )
        setToShowOrNotToSetPriority('Y');

      if (
        ((await userInfo.HasCenterPrivilege('C_SEND', center.centerId)) ||
          ((await userInfo.HasSystemPrivilege('SC_SEND')) &&
            (await userInfo.HasAllCentersAssigned))) &&
        isCompletedHL7Study
      )
        setToShowOrNotSendStudyOrReport('Y');

      // priority showing on the context menu with more than one studies selected / when multiple checked.
      if (checkedRowData.length > 1) {
        for (let index = 0; index < checkedRowData.length; index++) {
          var c = centerArray.find(
            item => item.centerName === checkedRowData[index].ffincenterid
          );
          if (
            !(
              (await userInfo.HasCenterPrivilege(
                'C_SET_PRIORITY',
                c.centerId
              )) ||
              ((await userInfo.HasSystemPrivilege('SC_SET_PRIORITY')) &&
                (await userInfo.HasAllCentersAssigned))
            )
          ) {
            setToShowOrNotToSetPriority('N');
            break;
          }
        }
      }
      // delete studies showing on the context menu with more than one studies selected / when multiple checked.
      if (checkedRowData.length > 1) {
        for (let index = 0; index < checkedRowData.length; index++) {
          var cntr = centerArray.find(
            item => item.centerName === checkedRowData[index].ffincenterid
          );
          if (
            ((await userInfo.HasCenterPrivilege(
              'C_DELETE_STUDY_SERIES',
              cntr.centerId
            )) ||
              ((await userInfo.HasSystemPrivilege('SC_DELETE_STUDY_SERIES')) &&
                (await userInfo.HasAllCentersAssigned))) &&
            isCompletedHL7Study
          )
            setToShowOrNotDeleteStudy('Y');
          else {
            setToShowOrNotDeleteStudy('N');
            break;
          }
        }
      }
    }
    fetchaccess();
  }, [userInfo, study, center.centerId, checkedRowData, centerArray]);

  const [attachDocOpen, setAttachDocOpen] = useState(false);

  const [style, setStyle] = useState('unCheckedRow');
  const new_StudyInstanceUID = study.StudyInstanceUID.replace(/\./g, '');

  useEffect(() => {
    if (document.querySelector(`#checkbox-${new_StudyInstanceUID}:checked`)) {
      setStyle('selectedRow');
      // updateCheckedRowData([...checkedRowData, study]);
    } else {
      setStyle('unCheckedRow');
      // const newArray = checkedRowData.filter(item => {
      //   return item.StudyInstanceUID !== study.StudyInstanceUID;
      // });
      // updateCheckedRowData(newArray);
    }
    const handleClick = () => updateClicked(false);
    window.addEventListener('click', handleClick);
    return () => {
      window.removeEventListener('click', handleClick);
    };
  }, [new_StudyInstanceUID, updateClicked]);

  let reportStatusSetting = study.ffinreportstatus;
  if (study.ffinreportstatus === '4') {
    reportStatusSetting = 'Other';
  } else if (study.ffinreportstatus === '3') {
    reportStatusSetting = 'Finalized';
  } else if (study.ffinreportstatus === '2') {
    reportStatusSetting = 'Preliminary';
  } else if (study.ffinreportstatus === '1') {
    reportStatusSetting = 'Drafted';
  } else {
    reportStatusSetting = 'No Report';
  }

  let prioritySetting = '';
  if (study.ffinpriority === '1') {
    prioritySetting = 'High';
  }
  if (study.ffinpriority === '2') {
    prioritySetting = 'Medium';
  }
  if (study.ffinpriority === '3') {
    prioritySetting = 'Low';
  }

  const dataArray = [];
  []
    .concat(tableMeta)
    .sort((a, b) => (a.order > b.order ? 1 : -1))
    .map((item, i) => {
      if (item.fieldName === 'ffinreportstatus') {
        dataArray.push({
          fieldName: item.fieldName,
          action: reportStatusSetting,
        });
      } else if (item.fieldName === 'ffinpriority') {
        dataArray.push({
          fieldName: item.fieldName,
          action: prioritySetting,
        });
        // } else if (item.fieldName === 'ffincenterid') {
        //   dataArray.push({
        //     fieldName: item.fieldName,
        //     action: centerDetailOfStudy.centerName,
        //   });
      } else if (item.fieldName !== 'Actions') {
        let actionValue = '';
        Object.entries(study).map(([key, val]) => {
          if (key === item.fieldName) {
            actionValue = val;
          }
        });
        dataArray.push({
          fieldName: item.fieldName,
          action: actionValue,
        });
      }
    });

  const DeleteStudy = async studyInstanceUID => {
    let errorMessage;
    const idtoken = JSON.parse(
      sessionStorage.getItem(
        'oidc.user:' +
          window.config.oidc[0].authority +
          ':' +
          window.config.oidc[0].client_id
      )
    );
    const bearerToken = 'bearer ' + idtoken.id_token;

    const requestOptions = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Orgin': '*',
        Authorization: bearerToken,
      },
    };
    const url =
      window.config.servers.dicomWeb[0].wadoRoot +
      '/studies/' +
      studyInstanceUID +
      '/';
    await fetch(url, requestOptions)
      .then(response => {
        if (response.status === 200) errorMessage = 'nil';
        else response.text();
      })
      .then(result => {
        if (result) errorMessage = result;
      })
      .catch(error => console.error('error', error));
    return errorMessage;
  };

  const snackbarMessage = (message, label) => {
    var x = document.getElementById('snackbar');
    x.className = `show ${label}`;
    x.innerHTML = message;
    setTimeout(function() {
      x.className = x.className.replace(`show ${label}`, '');
    }, 3000);
  };

  const prioritySubmenu = [];

  const sendStudySubmenu = [];
  const sendReportSubmenu = [];

  if(isSharingEnabled.isSmsEnabled === 'true'){
    isEnablePacs && sendStudySubmenu.push({
        className: 'itemWithBorder',
        title: 'SMS',
        onclick: () => {
          handleSendStudyReportData({
            inputType: 'number',
            sendThrough: 'SMS',
            sendTypeId: 'SMS_STUDY',
          });
          setIsSendPopupOpen(true);
        },
      });

    sendReportSubmenu.push({
      className: 'itemWithoutBorder',
      title: 'SMS',
      onclick: () => {
        handleSendStudyReportData({
          inputType: 'numeric',
          sendThrough: 'SMS',
          sendTypeId: 'SMS_REPORT',
        });
        setIsSendPopupOpen(true);
      },
    });
  }

    if(isSharingEnabled.isEmailEnabled === 'true'){
      isEnablePacs && sendStudySubmenu.push({
        className: 'itemWithBorder',
        title: 'Email',
        onclick: () => {
          handleSendStudyReportData({
            inputType: 'email',
            sendThrough: 'Email',
            sendTypeId: 'EMAIL_STUDY',
          });
          setIsSendPopupOpen(true);
        },
      });

    sendReportSubmenu.push({
      className: 'itemWithoutBorder',
      title: 'Email',
      onclick: () => {
        handleSendStudyReportData({
          inputType: 'email',
          sendThrough: 'Email',
          sendTypeId: 'EMAIL_REPORT',
        });
        setIsSendPopupOpen(true);
      },
    });
  }

    if(isSharingEnabled.isWhatsappEnabled === 'true'){
      isEnablePacs && sendStudySubmenu.push({
        className: 'itemWithoutBorder',
        title: 'WhatsApp',
        onclick: () => {
          handleSendStudyReportData({
            inputType: 'number',
            sendThrough: 'WhatsApp',
            sendTypeId: 'WHATSAPP_STUDY',
          });
          setIsSendPopupOpen(true);
        },
      });

    sendReportSubmenu.push({
      className: 'itemWithBorder',
      title: 'WhatsApp',
      onclick: () => {
        handleSendStudyReportData({
          inputType: 'numeric',
          sendThrough: 'WhatsApp',
          sendTypeId: 'WHATSAPP_REPORT',
        });
        setIsSendPopupOpen(true);
      },
    });
  }

  // right-click menu items
  const contextMenuItems = [
    {
      className: 'itemWithBorder',
      title: 'Set Priority',
      disabled: toShowOrNotToSetPriority === 'N' ? true : false,
      submenu: prioritySubmenu,
    },
  ];

  // the items which are displayed only for single study or multiple studies
  if (checkedRowData.length <= 1) {
    sendStudySubmenu.length > 0 && contextMenuItems.push({
        className: 'itemWithBorder',
        title: 'Send Study',
        disabled: toShowOrNotSendStudyOrReport === 'N' ? true : false,
        submenu: sendStudySubmenu,
      });
    if(study.ffinreportstatus === '3' && sendReportSubmenu.length > 0){
      contextMenuItems.push({
        className: 'itemWithBorder',
        title: 'Send Report',
        disabled: toShowOrNotSendStudyOrReport === 'N' ? true : false,
        submenu: sendReportSubmenu,
      });
    }

    contextMenuItems.unshift({
      className: 'itemWithBorder',
      title: 'Assign To User',
      disabled: toShowOrNotToAssignToUser === 'N' ? true : false,
      onclick: () => {
        setAssigntoOpen(true);
      },
    });

    contextMenuItems.unshift({
      className: 'itemWithBorder',
      title: 'View Report',
      disabled: toShowOrNotTheReport === 'N' ? true : false,
      onclick: () => {
        if (study.ffinreportstatus === '4') {
          setViewReportConfirmation(true);
        } else {
          const reportURL = `${window.config.servers.dicomWeb[0].reportRoot}/view/Editor/RichEditor?Mode=ReportEdit&StudyId=${study.StudyInstanceUID}&ReportId=id1&currentuser=${userId}`;
          window.open(reportURL, '_blank');
        }
      },
    });

    if (study.ffinpriority !== '1') {
      prioritySubmenu.push({
        className: 'itemWithBorder',
        title: 'High',
        onclick: () => ToSetPriority(1, study.StudyInstanceUID),
      });
    }
    if (study.ffinpriority !== '2') {
      prioritySubmenu.push({
        className: 'itemWithBorder',
        title: 'Medium',
        onclick: () => ToSetPriority(2, study.StudyInstanceUID),
      });
    }
    if (study.ffinpriority !== '3') {
      prioritySubmenu.push({
        className: 'itemWithBorder',
        title: 'Low',
        onclick: () => ToSetPriority(3, study.StudyInstanceUID),
      });
    }
    isEnablePacs && contextMenuItems.unshift({
        className: 'itemWithBorder',
        title: 'View Study',
        disabled: toShowOrNotToViewStudy === 'N' ? true : false,
        onclick: () => handleClick(study),
      });
    contextMenuItems.push({
      className: 'itemWithBorder',
      title: 'Attach Document',
      disabled: toShowOrNotDocument === 'N' ? true : false,
      onclick: () => setAttachDocOpen(true),
    });
    contextMenuItems.push({
      className: 'itemWithoutBorder',
      title: 'Study Info',
      onclick: () => setStudyinfoOpen(true),
    });
    contextMenuItems.push({
      className: 'itemWithoutBorder',
      title: 'Update Contact Details',
      onclick: () => setIsUpdateContactOpen(true),
    });
    contextMenuItems.push({
      className: 'itemWithBorder',
      title: 'Delete Study',
      disabled: toShowOrNotDeleteStudy === 'N' ? true : false,
      onclick: () => setDeleteConfirmation(true),
    });
  } else {
    contextMenuItems.push({
      className: 'itemWithBorder',
      title: 'Delete Studies',
      disabled: toShowOrNotDeleteStudy === 'N' ? true : false,
      onclick: () => setDeleteConfirmation(true),
    });
    contextMenuItems.push({
      className: 'itemWithBorder',
      title: 'Print Reports',
      disabled: toShowOrNotPrint === 'N' ? true : false,
      onclick: () => printMultipleTestResult(),
    });
    prioritySubmenu.push({
      className: 'itemWithBorder',
      title: 'High',
      onclick: () =>
        checkedRowData.map(item => ToSetPriority(1, item.StudyInstanceUID)),
    });
    prioritySubmenu.push({
      className: 'itemWithBorder',
      title: 'Medium',
      onclick: () =>
        checkedRowData.map(item => ToSetPriority(2, item.StudyInstanceUID)),
    });
    prioritySubmenu.push({
      className: 'itemWithBorder',
      title: 'Low',
      onclick: () =>
        checkedRowData.map(item => ToSetPriority(3, item.StudyInstanceUID)),
    });
  }

  const updateStudyinfoOpen = item => {
    setStudyinfoOpen(item);
  };
  const updateAssigntoOpen = item => {
    setAssigntoOpen(item);
  };

  const updateContactOpen = item => {
    setIsUpdateContactOpen(item);
  };
  const updateAttachDocOpen = item => {
    setAttachDocOpen(item);
  };
  const updateCommentContainer = item => {
    setIsCommentsOpen(item);
  };

  const updateViewReportContainer = item => {
    setIsViewReportPdfOpen(item);
  };

  const toShowTheViewIcon = () => {
    return toShowOrNotToViewStudy === 'N' ? true : false;
  };
  const toShowTheReportIcon = () => {
    return toShowOrNotTheReport === 'N' ? true : false;
  };
  const toShowDocumentIcon = () => {
    return toShowOrNotDocument === 'N' ? true : false;
  };
  const toShowClinicalNotesIcon = () => {
    return toShowOrNotClinicalNotes === 'N' ? true : false;
  };
  const toShowPrintIcon = () => {
    return toShowOrNotPrint === 'N'
      ? true
      : false || study.ffinreportstatus !== '3';
  };
  const handleCloseViewReport = () => {
    setNoReportContent({ isVisible: false, message: '' });
  };
  const scrollLeft = document.querySelector('#study-list-container').scrollLeft;
  const scrollTop = document.querySelector('#study-list-container').scrollTop;

  let dropdownSubmenuClassName = 'dropdown-submenu-left';
  const navPanel = document.getElementById('navigation-panel');
  let leftValue = points.x;
  let topValue = points.y;

  // height of the contextmenu
  let heightOfMainMenu = checkedRowData.length > 1 ? 220 : 371;
  if (isSideBarExpanded) {
    leftValue -= navPanel.offsetWidth;
  }
  if (window.innerWidth - points.x < 155) {
    leftValue -= 155;
  }
  // show dropdown on the left side of the mouse click
  if (window.innerWidth > 315 && window.innerWidth - points.x < 315) {
    dropdownSubmenuClassName = 'dropdown-submenu-right';
  }

  // context menu size changed when one study is selected
  if (
    window.innerHeight - points.y < heightOfMainMenu &&
    checkedRowData.length <= 1
  ) {
    topValue = points.y - 367;
    if(sendStudySubmenu.length<=0){
      topValue += 36;
    }
    if(study.ffinreportstatus != '3' || sendReportSubmenu.length <= 0){
      topValue += 36;
    }
    if(!isEnablePacs){
      topValue+=36
    }
  }

  // showing menu when clicked on the bottom position of the application
  // after selecting more than one study
  if (
    window.innerHeight - points.y < heightOfMainMenu &&
    checkedRowData.length > 1
  ) {
    topValue = points.y - 68;
    dropdownSubmenuClassName = dropdownSubmenuClassName + ' bottomsubmenu';
  }

  // context menu on the clicked cursor position
  const contextMenuItemsStyle = {
    top: `${topValue - 45 + scrollTop}px`,
    left: `${leftValue + scrollLeft}px`,
    display: isMobile ? 'none' : 'block',
  };

  const fetchDoc = async params => {
    const idtoken = JSON.parse(
      sessionStorage.getItem(
        'oidc.user:' +
          window.config.oidc[0].authority +
          ':' +
          window.config.oidc[0].client_id
      )
    );
    const bearerToken = 'bearer ' + idtoken.id_token;
    const downloadURL = `${window.config.servers.dicomWeb[0].apiRoot}/api/DownloadReportsToPrint/`;
    let reportData;
    await axios
      .post(
        downloadURL,
        { StudyParams: params },
        {
          headers: { Authorization: bearerToken },
          responseType: 'blob',
        }
      )
      .then(res => {
        if (res.status === 200) {
          const dfile = new Blob([res.data], {
            type: res.headers.get('Content-Type'),
          });
          reportData = URL.createObjectURL(dfile);
        }
      })
      .catch(err => {
        console.error(err);
      });
    return reportData;
  };

  const checkPrivilage = item => {
    var isCompletedHL7Study = !(
      study.ffinTransferMode === '1' && study.ffinStudyStatus === '10'
    );
    const centerId = item.centerId || item.ffincenterid;
    const hasCenterPrivilege = userInfo.HasCenterPrivilege(
      'C_PRINT_REPORT',
      centerId
    );
    const hasSystemPrivilege = userInfo.HasSystemPrivilege('SC_PRINT_REPORT');
    const hasAllCentersAssigned = userInfo.HasAllCentersAssigned;
    if (
      !(hasCenterPrivilege || (hasSystemPrivilege && hasAllCentersAssigned)) &&
      isCompletedHL7Study
    ) {
      setNoReportContent({
        isVisible: true,
        message: 'Your selected studies might not have access.',
      });
      return false;
    }
    return true;
  };

  const printSingleTestResult = async () => {
    if (study && study.StudyInstanceUID) {
      const hasPrivilege = checkPrivilage(study);
      if (!hasPrivilege) return;
      var params = [
        {
          StudyInstanceUID: study.StudyInstanceUID,
          AccessionNumber: study.AccessionNumber,
          ReportStatus: study.ffinreportstatus,
        },
      ];
    }
    printReports(params);
  };

  const printMultipleTestResult = () => {
    if (checkedRowData && checkedRowData.length > 0) {
      for (let item of checkedRowData) {
        if (item.ffinreportstatus != 3 && item.ffinreportstatus != 4) {
          setNoReportContent({
            isVisible: true,
            message: 'Some of your selected studies do not have a finalised report',
          });
          return;
        }
        const hasPrivilege = checkPrivilage(item);
        if (!hasPrivilege) return;
      }
    }
    var params = checkedRowData.map(item => ({
      StudyInstanceUID: item.StudyInstanceUID,
      AccessionNumber: item.AccessionNumber,
      ReportStatus: item.ffinreportstatus,
    }));
    printReports(params);
  };

  const printReports = async params => {
    try {
      const data = await fetchDoc(params);
      setBlobData(data);
      setIsPrintReportOpen(true);
      printJS({ printable: data, onPrintDialogClose: updatePrintTestResult });
    } catch (error) {
      console.error(error);
    }
  };

  const updatePrintTestResult = async () => {
    try {
      setIsPrintReportOpen(false);
      var headerData = new Headers();
      var idtoken = JSON.parse(
        sessionStorage.getItem(
          'oidc.user:' +
            window.config.oidc[0].authority +
            ':' +
            window.config.oidc[0].client_id
        )
      );
      var bearerToken = 'bearer ' + idtoken.id_token;
      headerData.append('Content-Type', 'application/json');
      headerData.append('Access-Control-Allow-Origin', '*');
      headerData.append('Authorization', bearerToken);
      const raw = JSON.stringify({
        "printStatus": "1"
      });

      const requestOptions = {
        method: 'POST',
        headers: headerData,
        body: raw,
        redirect: 'follow',
      };

      fetch(
        `${BaseURL}UpdatePrintStatus/${study.StudyInstanceUID}/`,
        requestOptions
      ).then(response => {
        if (response.status === 200) {
          console.log('Study instance UID sent successfully.');
        } else {
          const errorMessage = response.text();
          console.error(errorMessage);
          return response.text();
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const AttachDoc = () => {
    return <AttachDocument stdyUID={study.StudyInstanceUID} />;
  };
  const StudyInfo = () => {
    return <StudyInfoContent study={study} />;
  };
  const UpdateCont = () => {
    return (
      <UpdateContact
        updateContactOpen={updateContactOpen}
        patientID={study.PatientID}
      />
    );
  };

  const Comments = () => {
    return (
      <CommentsContainer
        study={study}
        stdyUID={study.StudyInstanceUID}
        user={user}
      />
    );
  };

  const [StudyParams, setStudyParams] = useState([]);

  const ToOpenSingleReportView = () => {
    if (study && study.StudyInstanceUID) {
      const params = [
        {
          StudyInstanceUID: study.StudyInstanceUID,
          AccessionNumber: study.AccessionNumber,
        },
      ];
      setStudyParams(params);
    }
    setIsViewReportPdfOpen(true);
  };
  const ToSetPriority = async (priority, studyInstanceUID) => {
    const url = `${window.config.servers.dicomWeb[0].apiRoot}/api/priority/`;
    var idtoken = JSON.parse(
      sessionStorage.getItem(
        'oidc.user:' +
          window.config.oidc[0].authority +
          ':' +
          window.config.oidc[0].client_id
      )
    );
    var bearerToken = 'bearer ' + idtoken.id_token;
    await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Orgin': '*',
        Authorization: bearerToken,
      },
      body: JSON.stringify({
        priority: priority,
        studyinstanceuid: studyInstanceUID,
      }),
    })
      .then(response => {
        if (response.status === 200) {
          handleFilterChange('StudyTime', '');
        } else return response.text();
      })
      .then(result => {
        if (result) {
          var x = document.getElementById('snackbar');
          x.className = 'show error';
          x.innerHTML = result;
          setTimeout(function() {
            x.className = x.className.replace('show error', '');
          }, 3000);
        }
      })
      .catch(error => console.error('error', error));
  };
  const AssignToUser = () => {
    let studyData = [];
    if (checkedRowData.length === 0) {
      studyData.push(study);
    } else {
      studyData = checkedRowData;
    }
    return (
      <AssignToUserContent
        updateAssigntoOpen={updateAssigntoOpen}
        studyData={studyData}
      />
    );
  };

  const Send_Study_Report = async study => {
    setIsSaveBtnDisable(true);
    const { PatientID, PatientName, StudyInstanceUID, AccessionNumber } = study;
    const validEmail = new RegExp('^[a-z0-9._:$!%-]+@[a-z0-9.-]+.[a-z]$');
    const {
      sendThrough,
      sendTypeId,
      emailId,
      phoneNo,
      sendToPatient,
    } = sendStudyReportData;
    switch (sendThrough) {
      case 'Email':
        if (!validEmail.test(emailId)) {
          snackbarMessage('Enter a valid email Id', 'warning');
          break;
        }
        await sendEmail(
          sendTypeId,
          emailId,
          StudyInstanceUID,
          PatientID,
          PatientName,
          sendToPatient,
          AccessionNumber
        );
        break;
      case 'SMS': {
        if (phoneNo.length <= 0) {
          snackbarMessage('Enter a phone no', 'warning');
          break;
        }
        await sendSMS(
          sendTypeId,
          phoneNo,
          PatientID,
          PatientName,
          StudyInstanceUID,
          sendToPatient,
          AccessionNumber
        );
        break;
      }
      case 'WhatsApp':
        if (phoneNo.length <= 0) {
          snackbarMessage('Enter a phone no', 'warning');
          break;
        }
        await sendWhatsappMessage(
          sendTypeId,
          phoneNo,
          PatientID,
          PatientName,
          AccessionNumber,
          StudyInstanceUID,
          sendToPatient
        )
        break;
      default:
        break;
    }
    setIsSaveBtnDisable(false);
  };

  const sendSMS = async (
    sendTypeId,
    phoneNo,
    PatientID,
    PatientName,
    StudyInstanceUID,
    sendToPatient,
    AccessionNumber
  ) => {
    var idtoken = JSON.parse(
      sessionStorage.getItem(
        'oidc.user:' +
          window.config.oidc[0].authority +
          ':' +
          window.config.oidc[0].client_id
      )
    );
    const headers = new Headers({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Orgin': '*',
    });
    headers.append('Authorization', 'bearer ' + idtoken.id_token);

    const sendSmsValue = JSON.stringify({
      Number: phoneNo,
      PatientName: PatientName,
      PatientId: PatientID,
      StudyInstanceUid: StudyInstanceUID,
      TemplateId: sendTypeId,
      SendToPatient: sendToPatient,
      AccessionNumber: AccessionNumber,
    });

    // Determine the endpoint based on sendToPatient value
    const endpoint = sendToPatient
      ? `${window.config.servers.dicomWeb[0].apiRoot}/api/sms/sendsms`
      : `${window.config.servers.dicomWeb[0].apiRoot}/api/sms/sendsmsviewer`;

    const requestOptions = {
      method: 'POST',
      headers: headers,
      body: sendSmsValue,
      redirect: 'follow',
    };
    let error = false;
    await fetch(endpoint, requestOptions)
      .then(response => {
        setIsSendPopupOpen(false);
        if (response.status === 200) {
          snackbarMessage('SMS Send Successfully', 'success');
        } else {
          error = true;
          return response.text();
        }
      })
      .then(result => {
        if (error) {
          snackbarMessage(result ?? 'Something went wrong', 'error');
        }
      })
      .catch(error => console.error(error));
  };

  const sendEmail = async (
    sendTypeId,
    emailId,
    StudyInstanceUID,
    PatientID,
    PatientName,
    sendToPatient,
    AccessionNumber
  ) => {
    var idtoken = JSON.parse(
      sessionStorage.getItem(
        'oidc.user:' +
          window.config.oidc[0].authority +
          ':' +
          window.config.oidc[0].client_id
      )
    );
    const headers = new Headers();
    headers.append('Access-Control-Allow-Orgin', '*');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', 'bearer ' + idtoken.id_token);

    const sendEmailValue = JSON.stringify({
      StudyInstanceUid: StudyInstanceUID,
      TemplateId: sendTypeId,
      ToAddress: emailId,
      PatientName: PatientName,
      PatientID: PatientID,
      SendToPatient: sendToPatient,
      AccessionNumber: AccessionNumber,
    });

    const requestOptions = {
      method: 'POST',
      headers: headers,
      body: sendEmailValue,
      redirect: 'follow',
    };
    let error = false;
    await fetch(
      window.config.servers.dicomWeb[0].apiRoot + '/api/sendemail',
      requestOptions
    )
      .then(response => {
        setIsSendPopupOpen(false);
        if (response.status === 200) {
          snackbarMessage('Email Send Successfully', 'success');
        } else {
          error = true;
          return response.text();
        }
      })
      .then(result => {
        if (error) {
          snackbarMessage(result ?? 'Something went wrong', 'error');
        }
      })
      .catch(error => console.error(error));
  };

  const sendWhatsappMessage = async (
    sendTypeId,
    phoneNo,
    PatientID,
    PatientName,
    AccessionNumber,
    StudyInstanceUID,
    sendToPatient
  ) => {
    var idtoken = JSON.parse(
      sessionStorage.getItem(
        'oidc.user:' +
          window.config.oidc[0].authority +
          ':' +
          window.config.oidc[0].client_id
      )
    );
    const headers = new Headers({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Orgin': '*',
    });
    headers.append('Authorization', 'bearer ' + idtoken.id_token);

    const sendWhatsappValue = JSON.stringify({
      MobileNumber: phoneNo,
      PatientName: PatientName,
      PatientID: PatientID,
      AccessionNumber: AccessionNumber,
      StudyInstanceUid: StudyInstanceUID,
      TemplateID: sendTypeId,
      SendToPatient: sendToPatient,
    });

    const requestOptions = {
      method: 'POST',
      headers: headers,
      body: sendWhatsappValue,
      redirect: 'follow',
    };
    let error = false;
    await fetch(
      window.config.servers.dicomWeb[0].apiRoot + '/api/sendwhatsappmessage',
      requestOptions
    )
      .then(response => {
        setIsSendPopupOpen(false);
        if (response.status === 200) {
          snackbarMessage('Whatsapp message Send Successfully', 'success');
        } else {
          error = true;
          return response.text();
        }
      })
      .then(result => {
        if (error) {
          snackbarMessage(result ?? 'Something went wrong', 'error');
        }
      })
      .catch(error => console.error(error));
  };

  const getColumnFontColor = (item)=>{

    if(item === 'Low'){
      return '#5b8a5beb';
    }
     if(item === 'Medium'){
      return '#959541ed';
    }
     if(item === 'High'){
      return '#c35a5aeb';
    }
    return '#ffffff';
  }

  const rowTemplate = (
    <>
      <tr
        key={tableRowKey}
        onClick={() => updateRow(study.StudyInstanceUID)}
        className={
          // Rightclick selection with context menu
          (clicked &&
            row === study.StudyInstanceUID &&
            (checkedRowData.length === 0 ||
              checkedRowData.find(
                item => item.StudyInstanceUID === study.StudyInstanceUID
              ))) ||
          // Left click selection
          (row === study.StudyInstanceUID &&
            (checkedRowData.length === 0 ||
              checkedRowData.find(
                item => item.StudyInstanceUID === study.StudyInstanceUID
              )))
            ? 'selectedRow'
            : style
        }
        onContextMenu={e => {
          e.preventDefault();
          updateClicked(true);
          if (clickedColumn) {
            updateClickedColumn(false);
          }
          updatePoints({ x: e.pageX, y: e.pageY });
          updateRow(study.StudyInstanceUID);
        }}
      >
        <td
          // dragOver={'Actions' === dragOver}
          style={
            'Actions' === dragOver
              ? { borderRight: '3px solid rgb(95, 93, 93)' }
              : {}
          }
        >
          <input
            type="checkbox"
            name="checkRow"
            id={`checkbox-${new_StudyInstanceUID}`}
            className="rowCheckbox"
            onChange={() => {
              if (
                document.querySelector(
                  `#checkbox-${new_StudyInstanceUID}:checked`
                )
              ) {
                updateCheckedRowData([...checkedRowData, study]);
              } else {
                const newArray = checkedRowData.filter(item => {
                  return item.StudyInstanceUID !== study.StudyInstanceUID;
                });
                updateCheckedRowData(newArray);
                setCheckUncheckAll(false);
              }
            }}
          />
          <button
            className="action-button"
            id="Reportaction-button"
            title="Report"
            style={{ display: isMobile ? 'none' : '' }}
            disabled={toShowTheReportIcon()}
            onClick={() => {
              if (study.ffinreportstatus === '4') {
                setViewReportConfirmation(true);
              } else {
                const reportURL = `${window.config.servers.dicomWeb[0].reportRoot}/view/Editor/RichEditor?Mode=ReportEdit&StudyId=${study.StudyInstanceUID}&ReportId=id1&currentuser=${userId}`;
                window.open(reportURL, '_blank');
              }
            }}
          >
            <Icon name="edit-document" />
          </button>
          {/* </Route> */}
          { isEnablePacs && <button
              className="action-button"
              disabled={toShowTheViewIcon()}
              onClick={() => handleClick(study)}
              title="Viewer"
            >
              <Icon name="visibility" />
          </button>}
          <button
            className="action-button"
            onClick={() => setAttachDocOpen(true)}
            disabled={toShowDocumentIcon()}
            title="Attach Document"
            style={{
              display: isMobile || toShowOrNotDocument === 'N' ? 'none' : '',
              color: study.ffinNoOfDocuments !== '0' && 'rgb(122, 194, 124)',
            }}
          >
            <Icon name="attachDocument" />
          </button>
          <button
            className="action-button"
            disabled={toShowClinicalNotesIcon()}
            onClick={() => setIsCommentsOpen(true)}
            title="Clinical Notes"
            style={{
              display:
                isMobile || toShowOrNotClinicalNotes === 'N' ? 'none' : '',
              color:
                study.ffinNoOfClinicalNotes !== '0' && 'rgb(122, 194, 124)',
            }}
          >
            <Icon name="clinicalNotes" />
          </button>
          <button
            className="action-button"
            disabled={toShowPrintIcon()}
            onClick={() => printSingleTestResult()}
            title="Print"
            data-print={study.ffinReportPrintStatus}
            style={{
              display: isMobile ? 'none' : '',
              color: study.ffinReportPrintStatus == '1' && 'rgb(122, 194, 124)',
            }}
          >
            <Icon name="print" />
          </button>
          <button
            className="action-button"
            title="View Report"
            onClick={() => ToOpenSingleReportView()}
            disabled={toShowTheReportIcon()}
            style={{
              display: isMobile && 'none',
              color: study.ffinreportstatus === '4' && 'rgb(122, 194, 124)',
            }}
          >
            <Icon name="view-document" />
          </button>
        </td>
        {dataArray.map((item, itemIndex) => {
          const tableData = tableMeta.find(
            data => data.fieldName === item.fieldName && data.show
          );
          if (tableData) {
            return (
              <>
                <td
                  key={`${item.fieldName}-${itemIndex}`}
                  onDoubleClick={() => {
                    if (toShowOrNotToViewStudy !== 'N' && isEnablePacs ) {
                      handleClick(study);
                    }
                  }}
                  className={classNames({ 'empty-value': !item.action })}
                  title={item.action}
                  // dragOver={item.fieldName === dragOver}
                  style={{
                    // color: item.action === 'Finalized'? 'rgb(143 245 145)' : 'white',
                    // fontSize: item.action === 'Finalized'? "14px" : '12px',
                    // fontWeight: item.action === 'Finalized'? "1000" : 'normal',
                    backgroundColor:
                      item.action === 'Finalized' ? '#558256' : 'transparent',
                    borderRight:
                      item.fieldName === dragOver
                        ? '3px solid rgb(95, 93, 93)'
                        : 'none',
                    color: getColumnFontColor(item.action),
                        fontWeight:item.fieldName==='ffinpriority'&&'bold'
                  }}
                >
                  {item.action || `${t(' ')}`}
                </td>
                {/* Column Resizer */}
                {!isMobile && <td className="tdcolumn" key={`-${itemIndex}`} />}
              </>
            );
          }
        })}
        {clicked && // rightclick
        contextMenuItems.length !== 0 && // context menu items array empty
        row === study.StudyInstanceUID && // selected row
          (checkedRowData.length === 0 || // array length 0
            checkedRowData.find(
              item => item.StudyInstanceUID === study.StudyInstanceUID // array contains the selected row
            )) && (
            <div className="contextMenu" style={contextMenuItemsStyle}>
              <ul className="dropdown show">
                {contextMenuItems.map(item => {
                  const depthLevel = 1;
                  return (
                    <>
                      <MenuItems
                        items={item}
                        depthLevel={depthLevel}
                        dropdownSubmenuClassName={dropdownSubmenuClassName}
                      />
                    </>
                  );
                })}
              </ul>
            </div>
          )}
      </tr>
      {studyinfoOpen && (
        <CusModal>
          <UiModal
            modalSize={'md'}
            updateisOpen={updateStudyinfoOpen}
            title="Study Details"
            footerVisibility={false}
          >
            <StudyInfo />
          </UiModal>
        </CusModal>
      )}
      {assigntoOpen && (
        <CusModal>
          <UiModal
            modalSize={'md'}
            updateisOpen={updateAssigntoOpen}
            title="Select User"
            footerVisibility={false}
          >
            <AssignToUser />
          </UiModal>
        </CusModal>
      )}
      {attachDocOpen && (
        <CusModal>
          <UiModal
            modalSize={'md'}
            updateisOpen={updateAttachDocOpen}
            title="Attach Document"
            footerVisibility={false}
          >
            <AttachDoc />
          </UiModal>
        </CusModal>
      )}
      {isCommentsOpen && (
        <CusModal>
          <UiModal
            modalSize={'md'}
            updateisOpen={updateCommentContainer}
            title="Comments"
            footerVisibility={false}
          >
            <Comments />
          </UiModal>
        </CusModal>
      )}

      {isUpdateContactOpen && (
        <CusModal>
          <UiModal
            modalSize={'sm'}
            updateisOpen={updateContactOpen}
            title="Update Contact Details"
            footerVisibility={false}
          >
            <UpdateCont />
          </UiModal>
        </CusModal>
      )}

      {isSendPopupOpen && (
        <CusModal>
          <UiModal
            modalSize={'sm'}
            updateisOpen={value => setIsSendPopupOpen(value)}
            title={'Send ' + sendStudyReportData.sendThrough}
            footerVisibility={true}
            closeBtnTitle="Cancel"
            saveBtnTitle="Send"
            saveBtnDisable={isSaveBtnDisable}
            onClose={() => setIsSendPopupOpen(false)}
            onSave={() => Send_Study_Report(study)}
          >
            <ContactDetails
              patientID={study.PatientID}
              sendPopupData={sendStudyReportData}
              handleSendStudyReportData={handleSendStudyReportData}
            />
          </UiModal>
        </CusModal>
      )}

      {deleteConfirmation && (
        <CusModal>
          <UiModal
            modalSize={'sm'}
            updateisOpen={value => setDeleteConfirmation(value)}
            title={
              checkedRowData.length > 1 ? 'Delete Studies' : 'Delete Study'
            }
            footerVisibility={true}
            closeBtnTitle="No"
            saveBtnTitle="Yes"
            saveBtnDisable={isSaveBtnDisable}
            onClose={() => setDeleteConfirmation(false)}
            onSave={async () => {
              setIsSaveBtnDisable(true);
              let isdeleted = 0;
              let errorResponse = null;
              if (checkedRowData.length > 1) {
                for (let i = 0; i < checkedRowData.length; i++) {
                  const item = checkedRowData[i];
                  errorResponse = await DeleteStudy(item.StudyInstanceUID);
                  if (errorResponse === 'nil') isdeleted++;
                  else break;
                }
              } else errorResponse = await DeleteStudy(study.StudyInstanceUID);

              if (errorResponse)
                if (
                  errorResponse === 'nil' &&
                  (checkedRowData.length > 1
                    ? checkedRowData.length === isdeleted
                    : true)
                ) {
                  setDeleteConfirmation(false);
                  handleFilterChange('StudyTime', '');
                  snackbarMessage(
                    `${
                      checkedRowData.length > 1 ? 'Studies' : 'Study'
                    } Deleted`,
                    'success'
                  );
                } else {
                  setDeleteConfirmation(false);
                  handleFilterChange('StudyTime', '');
                  snackbarMessage(errorResponse, 'error');
                }
              setIsSaveBtnDisable(false);
            }}
          >
            <ConfirmationPopup
              message={`Are you sure, you want to delete ${
                checkedRowData.length > 1 ? 'these studies' : 'this study'
              }`}
            />
          </UiModal>
        </CusModal>
      )}
      {isPrintReportOpen && (
        <div>
          <iframe
            title="PdfViewer"
            id="iframe"
            src={blobData}
            className="PdfViewer "
            style={{ width: '100%', display: 'none' }}
          />
        </div>
      )}

      {isViewReportPdfOpen && (
        <CusModal>
          <UiModal
            modalSize={'lg'}
            updateisOpen={updateViewReportContainer}
            title="View Report"
            footerVisibility={false}
          >
            <ViewReportPdf
              StudyInstanceUID={study.StudyInstanceUID}
              downloadURL={
                JSON.parse(study.ffinreportstatus) > 0 &&
                study.ffinreportstatus !== '4'
                  ? `${window.config.servers.dicomWeb[0].apiRoot}/api/ViewReportPdf/`
                  : `${window.config.servers.dicomWeb[0].apiRoot}/api/ViewSRReportPdf/`
              }
            />
          </UiModal>
        </CusModal>
      )}
      {noReportContent.isVisible && (
        <CusModal>
          <WarningModal
            updateisOpen={updateViewReportContainer}
            message={noReportContent.message}
            onClose={handleCloseViewReport}
          ></WarningModal>
        </CusModal>
      )}

      {viewReportConfirmation && (
        <CusModal>
          <UiModal
            modalSize={'sm'}
            updateisOpen={value => setViewReportConfirmation(value)}
            title="Report Confirmation"
            footerVisibility={true}
            closeBtnTitle="No"
            saveBtnTitle="Yes"
            onClose={() => setViewReportConfirmation(false)}
            onSave={() => {
              const reportURL = `${window.config.servers.dicomWeb[0].reportRoot}/view/Editor/RichEditor?Mode=ReportEdit&StudyId=${study.StudyInstanceUID}&ReportId=id1&currentuser=${userId}`;
              window.open(reportURL, '_blank');
              setViewReportConfirmation(false);
            }}
          >
            <p>{'SR Report exists for the study.'}</p>
            <p>{'Do you want to proceed with creating a new report ?'}</p>
          </UiModal>
        </CusModal>
      )}
    </>
  );
  return rowTemplate;
}

TableRow.propTypes = {
  isHighlighted: PropTypes.bool,
  study: PropTypes.any,
  displaySize: PropTypes.string,
  isSideBarExpanded: PropTypes.bool,
};

TableRow.defaultProps = {
  isHighlighted: false,
};

const ContactDetails = props => {
  const { patientID, sendPopupData, handleSendStudyReportData } = props;
  useEffect(() => {
    fetchPatientDetails(patientID);
  }, [patientID]);

  const fetchPatientDetails = async patientID => {
    var idtoken = JSON.parse(
      sessionStorage.getItem(
        'oidc.user:' +
          window.config.oidc[0].authority +
          ':' +
          window.config.oidc[0].client_id
      )
    );
    var bearerToken = 'bearer ' + idtoken.id_token;
    await fetch(
      `${window.config.servers.dicomWeb[0].apiRoot}/api/fetchContactDetails/${patientID}/`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Orgin': '*',
          Authorization: bearerToken,
        },
        body: '',
        redirect: 'follow',
      }
    )
      .then(response => response.json())
      .then(result => {
        if (result) {
          handleSendStudyReportData({
            emailId: result.emailID ? result.emailID : '',
          });
          handleSendStudyReportData({
            phoneNo: result.contactNo ? result.contactNo : '',
          });
        }
      })
      .catch(error => console.error('error', error));
  };

  return (
    <div className="contact-details-div">
      <label className="contact-label">Send To</label>
      <div className="contact-radio-main-div">
        <div className="contact-radio-div">
          <input
            type="radio"
            name="sendTo"
            id="isPatient"
            className="contact-radio"
            checked={sendPopupData.sendToPatient}
            onChange={e => {
              const value = e.target.checked;
              handleSendStudyReportData({ sendToPatient: value });
            }}
          />
          <label htmlFor="isPatient" className="contact-radio-label">
            Patient
          </label>
        </div>
        <div className="contact-radio-div">
          <input
            type="radio"
            name="sendTo"
            id="isRadiologist"
            className="contact-radio"
            checked={!sendPopupData.sendToPatient}
            onChange={e => {
              const value = e.target.checked;
              handleSendStudyReportData({ sendToPatient: !value });
            }}
          />
          <label htmlFor="isRadiologist" className="contact-radio-label">
            Radiologist
          </label>
        </div>
      </div>
      <label className="contact-label">
        {sendPopupData.inputType === 'email' ? 'Email' : 'Phone Number'}
      </label>
      {sendPopupData.inputType === 'email' ? (
        <input
          inputMode="email"
          type="email"
          name="emailId"
          id="contact-emailId"
          className="contact-textbox"
          value={sendPopupData.emailId}
          autoFocus
          required
          onChange={e => {
            const value = e.target.value;
            handleSendStudyReportData({ emailId: value });
          }}
        />
      ) : (
        <input
          inputMode="numeric"
          type="text"
          name="phoneNo"
          id="contact-phoneNo"
          className="contact-textbox"
          value={sendPopupData.phoneNo}
          autoFocus
          required
          onChange={e => {
            const value = e.target.value;
            if (/^\d*$/.test(value) && value.length <= 20)
              handleSendStudyReportData({ phoneNo: value });
          }}
        />
      )}
    </div>
  );
};

ContactDetails.propTypes = {
  patientID: PropTypes.string,
  sendPopupData: PropTypes.any,
  handleSendStudyReportData: PropTypes.func,
};

export { StudyList };
