import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

// import PDFViewer from 'pdf-viewer-reactjs';
import WhiteLabelingContext from '../context/WhiteLabelingContext';
import UserManagerContext from '../context/UserManagerContext';
import AppContext from '../context/AppContext';
import ConnectedHeader from '../connectedComponents/ConnectedHeader';
import './ReportPdfViewer.styl';
import ReportPdfFrame from '../ReportPdfFrame/ReportPdfFrame';

const ReportPdfViewer = ({ match: routeMatch, location: routeLocation }) => {
  const {
    project,
    location,
    dataset,
    dicomStore,
    StudyInstanceUID,
    AccessionNumber,
  } = routeMatch.params;

  return (
    <div className="ReportPdfPage">
      <WhiteLabelingContext.Consumer>
        {whiteLabeling => (
          <UserManagerContext.Consumer>
            {userManager => (
              <AppContext.Consumer>
                {appContext => (
                  <ConnectedHeader
                    userManager={userManager}
                    useLogo={true}
                    reportViewer={true}
                  >
                    {whiteLabeling &&
                      whiteLabeling.createLogoComponentFn &&
                      whiteLabeling.createLogoComponentFn(React)}
                  </ConnectedHeader>
                )}
              </AppContext.Consumer>
            )}
          </UserManagerContext.Consumer>
        )}
      </WhiteLabelingContext.Consumer>
        <ReportPdfFrame
          AccessionNumber={decodeURIComponent(AccessionNumber)}
          StudyInstanceUID={decodeURIComponent(StudyInstanceUID)}
          downloadURL={`${window.config.servers.dicomWeb[0].apiRoot}/api/DownloadFinalizedReport/`}
        />
      </div>
  );
};

ReportPdfViewer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      StudyInstanceUID: PropTypes.string.isRequired,
      dataset: PropTypes.string,
      dicomStore: PropTypes.string,
      location: PropTypes.string,
      project: PropTypes.string,
    }),
  }),
  location: PropTypes.any,
};

export default withRouter(ReportPdfViewer);
