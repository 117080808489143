// TODO: A way to add Icons that don't already exist?
// - Register them and add
// - Include SVG Source/Inline?
// - By URL, or own component?

// What KINDS of toolbar buttons do we have...
// - One's that dispatch commands
// - One's that set tool's active
// - More custom, like CINE
//    - Built in for one's like this, or custom components?

// Visible?
// Disabled?
// Based on contexts or misc. criteria?
//  -- ACTIVE_ROUTE::VIEWER
//  -- ACTIVE_VIEWPORT::CORNERSTONE
// setToolActive commands should receive the button event that triggered
// so we can do the "bind to this button" magic

import VTKMPRToolbarButton from '../../vtk/src/toolbarComponents/VTKMPRToolbarButton';
import ReferenceLinesToolbarButton from './toolbarComponents/ReferenceLinesToolbarButton';
import SyncScrollToolbarButton from './toolbarComponents/SyncScrollToolbarButton';
import IntelliLinkToolbarButton from './toolbarComponents/IntelliLinkToolbarButton';
import { isMobile } from 'react-device-detect';

const TOOLBAR_BUTTON_TYPES = {
  COMMAND: 'command',
  SET_TOOL_ACTIVE: 'setToolActive',
  BUILT_IN: 'builtIn',
};

const TOOLBAR_BUTTON_BEHAVIORS = {
  CINE: 'CINE',
  DOWNLOAD_SCREEN_SHOT: 'DOWNLOAD_SCREEN_SHOT',
};

/* TODO: Export enums through a extension manager. */
const enums = {
  TOOLBAR_BUTTON_TYPES,
  TOOLBAR_BUTTON_BEHAVIORS,
};

const definitions = isMobile
  ? [
      {
        id: 'Zoom',
        label: 'Zoom',
        icon: 'search-plus',
        //
        type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
        commandName: 'setToolActive',
        commandOptions: { toolName: 'Zoom' },
      },
      {
        id: 'Pan',
        label: 'Pan',
        icon: 'arrows',
        type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
        commandName: 'setToolActive',
        commandOptions: { toolName: 'Pan' },
      },
      {
        id: 'StackScroll',
        label: 'Stack Scroll',
        icon: 'bars',
        //
        type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
        commandName: 'setToolActive',
        commandOptions: { toolName: 'StackScroll' },
      },
      {
        id: 'Annotations',
        label: 'Draw',
        icon: 'annotationTools',
        buttons: [
          {
            id: 'Length',
            label: 'Length',
            icon: 'measure-temp',
            //
            type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
            commandName: 'setToolActive',
            commandOptions: { toolName: 'Length' },
          },
          {
            id: 'CurvedLine',
            label: 'CurvedLine',
            icon: 'curve',
            //
            type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
            commandName: 'setToolActive',
            commandOptions: { toolName: 'CurvedLine' },
          },
          {
            id: 'CTR',
            label: 'CTR',
            icon: 'ctr',
            //
            type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
            commandName: 'setToolActive',
            commandOptions: { toolName: 'CTR' },
          },
          {
            id: 'ArrowAnnotate',
            label: 'Annotate',
            icon: 'measure-non-target',
            //
            type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
            commandName: 'setToolActive',
            commandOptions: { toolName: 'ArrowAnnotate' },
          },
          {
            id: 'Angle',
            label: 'Angle',
            icon: 'angle-left',
            //
            type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
            commandName: 'setToolActive',
            commandOptions: { toolName: 'Angle' },
          },
          {
            id: 'CobbAngle',
            label: 'CobbAngle',
            icon: 'cobb-angle',
            //
            type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
            commandName: 'setToolActive',
            commandOptions: { toolName: 'CobbAngle' },
          },
          {
            id: 'EllipticalRoi',
            label: 'Ellipse',
            icon: 'circle-o',
            //
            type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
            commandName: 'setToolActive',
            commandOptions: { toolName: 'EllipticalRoi' },
          },
          {
            id: 'RectangleRoi',
            label: 'Rectangle',
            icon: 'square-o',
            //
            type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
            commandName: 'setToolActive',
            commandOptions: { toolName: 'RectangleRoi' },
          },
        ],
      },
      {
        id: 'Clear',
        label: 'Clear',
        icon: 'trash',
        //
        type: TOOLBAR_BUTTON_TYPES.COMMAND,
        commandName: 'clearAnnotations',
      },
      {
        id: 'Orientation',
        label: 'Orientation',
        icon: 'orientation',
        buttons: [
          {
            id: 'FlipH',
            label: 'Flip H',
            icon: 'ellipse-h',
            //
            type: TOOLBAR_BUTTON_TYPES.COMMAND,
            commandName: 'flipViewportHorizontal',
          },
          {
            id: 'FlipV',
            label: 'Flip V',
            icon: 'ellipse-v',
            //
            type: TOOLBAR_BUTTON_TYPES.COMMAND,
            commandName: 'flipViewportVertical',
          },
          {
            id: 'RotateRight',
            label: 'Rotate Right',
            icon: 'rotate-right',
            //
            type: TOOLBAR_BUTTON_TYPES.COMMAND,
            commandName: 'rotateViewportCW',
          },
          {
            id: 'RotateLeft',
            label: 'Rotate Left',
            icon: 'rotate-left',
            //
            type: TOOLBAR_BUTTON_TYPES.COMMAND,
            commandName: 'rotateViewportCCW',
          },
        ],
      },
      {
        id: 'Wwwc',
        label: 'Levels',
        icon: 'level',
        //
        type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
        commandName: 'setToolActive',
        commandOptions: { toolName: 'Wwwc' },
      },
      {
        id: 'Invert',
        label: 'Invert',
        icon: 'adjust',
        //
        type: TOOLBAR_BUTTON_TYPES.COMMAND,
        commandName: 'invertViewport',
      },
      {
        id: 'Reset',
        label: 'Reset',
        icon: 'reset',
        //
        type: TOOLBAR_BUTTON_TYPES.COMMAND,
        commandName: 'resetViewport',
      },
      {
        id: 'Download',
        label: 'Export',
        icon: 'create-screen-capture',
        //
        type: TOOLBAR_BUTTON_TYPES.BUILT_IN,
        options: {
          behavior: TOOLBAR_BUTTON_BEHAVIORS.DOWNLOAD_SCREEN_SHOT,
          togglable: true,
        },
      },
      {
        id: 'DragProbe',
        label: 'Probe',
        icon: 'dot-circle',
        //
        type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
        commandName: 'setToolActive',
        commandOptions: { toolName: 'DragProbe' },
      },
      {
        id: 'Magnify',
        label: 'Magnify',
        icon: 'circle',
        //
        type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
        commandName: 'setToolActive',
        commandOptions: { toolName: 'Magnify' },
      },
    ]
  : [
      {
        id: 'Length',
        label: 'Length',
        icon: 'measure-temp',
        //
        type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
        commandName: 'setToolActive',
        commandOptions: { toolName: 'Length' },
      },
      {
        id: 'CurvedLine',
        label: 'CurvedLine',
        icon: 'curve',
        //
        type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
        commandName: 'setToolActive',
        commandOptions: { toolName: 'CurvedLine' },
      },
      {
        id: 'CTR',
        label: 'CTR',
        icon: 'ctr',
        //
        type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
        commandName: 'setToolActive',
        commandOptions: { toolName: 'CTR' },
      },
      {
        id: 'ArrowAnnotate',
        label: 'Annotate',
        icon: 'measure-non-target',
        //
        type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
        commandName: 'setToolActive',
        commandOptions: { toolName: 'ArrowAnnotate' },
      },
      {
        id: 'Angle',
        label: 'Angle',
        icon: 'angle-left',
        //
        type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
        commandName: 'setToolActive',
        commandOptions: { toolName: 'Angle' },
      },
      {
        id: 'CobbAngle',
        label: 'CobbAngle',
        icon: 'cobb-angle',
        //
        type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
        commandName: 'setToolActive',
        commandOptions: { toolName: 'CobbAngle' },
      },
      {
        id: 'EllipticalRoi',
        label: 'Ellipse',
        icon: 'circle-o',
        //
        type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
        commandName: 'setToolActive',
        commandOptions: { toolName: 'EllipticalRoi' },
      },
      {
        id: 'RectangleRoi',
        label: 'Rectangle',
        icon: 'square-o',
        //
        type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
        commandName: 'setToolActive',
        commandOptions: { toolName: 'RectangleRoi' },
      },
      {
        id: 'DragProbe',
        label: 'Probe',
        icon: 'dot-circle',
        //
        type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
        commandName: 'setToolActive',
        commandOptions: { toolName: 'DragProbe' },
      },
      {
        id: 'Magnify',
        label: 'Magnify',
        icon: 'circle',
        //
        type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
        commandName: 'setToolActive',
        commandOptions: { toolName: 'Magnify' },
      },
      // {
      //   id: 'BrushEraser',
      //   label: 'Eraser',
      //   icon: 'eraser',
      //   //
      //   type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
      //   commandName: 'setToolActive',
      //   commandOptions: { toolName: 'BrushEraser' },
      // },
      // {
      //   id: 'Clear',
      //   label: 'Clear',
      //   icon: 'trash',
      //   //
      //   type: TOOLBAR_BUTTON_TYPES.COMMAND,
      //   commandName: 'clearAnnotations',
      // },
      {
        id: 'FlipH',
        label: 'Flip H',
        icon: 'ellipse-h',
        //
        type: TOOLBAR_BUTTON_TYPES.COMMAND,
        commandName: 'flipViewportHorizontal',
      },
      {
        id: 'FlipV',
        label: 'Flip V',
        icon: 'ellipse-v',
        //
        type: TOOLBAR_BUTTON_TYPES.COMMAND,
        commandName: 'flipViewportVertical',
      },
      {
        id: 'RotateRight',
        label: 'Rotate Right',
        icon: 'rotate-right',
        //
        type: TOOLBAR_BUTTON_TYPES.COMMAND,
        commandName: 'rotateViewportCW',
      },
      {
        id: 'RotateLeft',
        label: 'Rotate Left',
        icon: 'rotate-left',
        //
        type: TOOLBAR_BUTTON_TYPES.COMMAND,
        commandName: 'rotateViewportCCW',
      },
      {
        id: 'StackScroll',
        label: 'Stack Scroll',
        icon: 'bars',
        //
        type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
        commandName: 'setToolActive',
        commandOptions: { toolName: 'StackScroll' },
      },
      {
        id: 'Zoom',
        label: 'Zoom',
        icon: 'search-plus',
        //
        type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
        commandName: 'setToolActive',
        commandOptions: { toolName: 'Zoom' },
      },
      {
        id: 'Pan',
        label: 'Pan',
        icon: 'arrows',
        //
        type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
        commandName: 'setToolActive',
        commandOptions: { toolName: 'Pan' },
      },
      {
        id: 'Wwwc',
        label: 'Levels',
        icon: 'level',
        //
        type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
        commandName: 'setToolActive',
        commandOptions: { toolName: 'Wwwc' },
      },
      {
        id: 'Invert',
        label: 'Invert',
        icon: 'adjust',
        //
        type: TOOLBAR_BUTTON_TYPES.COMMAND,
        commandName: 'invertViewport',
      },
      {
        id: 'Reset',
        label: 'Reset',
        icon: 'reset',
        //
        type: TOOLBAR_BUTTON_TYPES.COMMAND,
        commandName: 'resetViewport',
      },
      {
        id: 'Download',
        label: 'Export',
        icon: 'create-screen-capture',
        //
        type: TOOLBAR_BUTTON_TYPES.BUILT_IN,
        options: {
          behavior: TOOLBAR_BUTTON_BEHAVIORS.DOWNLOAD_SCREEN_SHOT,
          togglable: true,
        },
      },
      {
        id: 'SyncScroll',
        label: 'Sync',
        icon: 'syncImageByPosition',
        CustomComponent: SyncScrollToolbarButton,
        //
        type: TOOLBAR_BUTTON_TYPES.COMMAND,
        commandName: 'toggleSynchronizer',
        options: {
          togglable: true,
        },
      },
      {
        id: 'SyncImageNumber',
        label: 'SyncImageNumber',
        icon: 'syncImageByNumber',
        CustomComponent: SyncScrollToolbarButton,
        //
        type: TOOLBAR_BUTTON_TYPES.COMMAND,
        commandName: 'toggleSyncImageNumber',
        options: {
          togglable: true,
        },
      },
      {
        id: 'ManualSync',
        label: 'ManualSync',
        icon: 'syncImageManually',
        CustomComponent: SyncScrollToolbarButton,
        //
        type: TOOLBAR_BUTTON_TYPES.COMMAND,
        commandName: 'toggleManualSync',
        options: {
          togglable: true,
        },
      },
      {
        id: '2DMPR',
        label: '2D MPR',
        icon: 'cube',
        //
        CustomComponent: VTKMPRToolbarButton,
        type: TOOLBAR_BUTTON_TYPES.COMMAND,
        commandName: 'mpr2d',
        context: 'ACTIVE_VIEWPORT::CORNERSTONE',
      },
      {
        id: 'ReferenceLines',
        label: 'Reference lines',
        icon: 'referenceLine',

        CustomComponent: ReferenceLinesToolbarButton,
        type: TOOLBAR_BUTTON_TYPES.COMMAND,
        commandName: 'showReferenceLines',
        options: {
          togglable: true,
        },
      },
      {
        id: 'IntelliLink',
        label: 'IntelliLink',
        icon: 'intelliLink',
        //
        CustomComponent: IntelliLinkToolbarButton,
        type: TOOLBAR_BUTTON_TYPES.COMMAND,
        commandName: 'enableIntelliLinkTool',
        options: {
          togglable: true,
        },
      },
      {
        id: 'Cine',
        label: 'CINE',
        icon: 'youtube',
        //
        type: TOOLBAR_BUTTON_TYPES.BUILT_IN,
        options: {
          behavior: TOOLBAR_BUTTON_BEHAVIORS.CINE,
        },
      },

      // {
      //   id: 'More',
      //   label: 'More',
      //   icon: 'ellipse-circle',
      //   buttons: [
      //     {
      //       id: 'WwwcRegion',
      //       label: 'ROI Window',
      //       icon: 'stop',
      //       //
      //       type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
      //       commandName: 'setToolActive',
      //       commandOptions: { toolName: 'WwwcRegion' },
      //     },
      //     {
      //       id: 'Bidirectional',
      //       label: 'Bidirectional',
      //       icon: 'measure-target',
      //       //
      //       type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
      //       commandName: 'setToolActive',
      //       commandOptions: { toolName: 'Bidirectional' },
      //     },
      //   ],
      // },
      // {
      //   id: 'Presets',
      //   label: 'Presets',
      //   icon: 'ellipse-circle',
      //   buttons: [
      //     {
      //       id: 'W/L Preset 1',
      //       commandName: 'windowLevelPreset1',
      //       label: 'W/L Preset 1',
      //     },
      //     {
      //       id: 'W/L Preset 2',
      //       commandName: 'windowLevelPreset2',
      //       label: 'W/L Preset 2',
      //     },
      //     {
      //       id: 'W/L Preset 3',
      //       commandName: 'windowLevelPreset3',
      //       label: 'W/L Preset 3',
      //     },
      //     {
      //       id: 'W/L Preset 4',
      //       commandName: 'windowLevelPreset4',
      //       label: 'W/L Preset 4',
      //     },
      //     {
      //       id: 'W/L Preset 5',
      //       commandName: 'windowLevelPreset5',
      //       label: 'W/L Preset 5',
      //     },
      //     {
      //       id: 'W/L Preset 6',
      //       commandName: 'windowLevelPreset6',
      //       label: 'W/L Preset 6',
      //     },
      //     {
      //       id: 'W/L Preset 7',
      //       commandName: 'windowLevelPreset7',
      //       label: 'W/L Preset 7',
      //     },
      //   ],
      // },
    ];

export default {
  definitions,
  defaultContext: 'ACTIVE_VIEWPORT::CORNERSTONE',
};
