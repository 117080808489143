import React, { useRef } from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import MenuItems from '../../../../viewer/src/components/DropedownComponent/DropedownComponentMenuItems';
import { useSnackbarContext } from '@ohif/ui';

function RightClickMenu(props) {
  const {
    top,
    left,
    field,
    updateTableMeta,
    updateTableMetaOrder,
    handleClick,
    handleRef,
    tableMeta,
    isSideBarExpanded,
  } = props;
  const snackbar = useSnackbarContext();
  const [hiddenColumns, setHiddenColumns] = useState(addHiddenData(tableMeta));
  const [visibleColumns, setVisibleColumns] = useState(
    addVisibleData(tableMeta)
  );

  const contextMenuItems = [];
  if (field.fieldName != 'Actions') {
    contextMenuItems.push({
      title:
        field.fieldName === 'StudyDate'
          ? `Hide Study Date`
          : `Hide ${field.displayText}`,
      onclick: () => {
        handleClick(false);
        updateTableMeta(field);
      },
    });
  }
  if (visibleColumns.length != 0) {
    contextMenuItems.push({
      title: 'Hide Columns',
      submenu: visibleColumns,
    });
  }
  if (hiddenColumns.length != 0) {
    contextMenuItems.push({
      title: 'Show Columns',
      submenu: hiddenColumns,
    });
  }

  function addVisibleData(tableMeta) {
    const visibleColumns = [];
    tableMeta.map(item => {
      // adding visible columns
      if (item.show && item.fieldName != 'Actions') {
        const visibleItem = {
          content: item,
          title: <table>{ListVisibleItem(item)}</table>,
          ischecked: false,
        };
        visibleColumns.push(visibleItem);
      }
    });
    if (visibleColumns.length != 0) {
      visibleColumns.unshift({
        title: Hide(),
        content: {},
      });
    }
    return visibleColumns;
  }

  function addHiddenData(tableMeta) {
    const hiddenColumns = [];
    tableMeta.map(item => {
      // adding hidden columns
      if (!item.show) {
        const hiddenItem = {
          content: item,
          title: <table>{ListHiddenItem(item)}</table>,
          ischecked: false,
        };
        hiddenColumns.push(hiddenItem);
      }
    });
    if (hiddenColumns.length != 0) {
      hiddenColumns.unshift({
        title: Show(),
        content: {},
      });
    }
    return hiddenColumns;
  }

  function ListVisibleItem(element) {
    return (
      <>
        <tr className="eachitems">
          <td>
            <input
              type="checkbox"
              className="inputCheckbox"
              name={`inputCheckbox${element.fieldName}`}
              value={element.fieldName}
              onChange={() => {
                const currentArray = [];
                visibleColumns.map(item => {
                  if (item.content.fieldName === element.fieldName) {
                    item.ischecked = !item.ischecked;
                  }
                  currentArray.push(item);
                });
                setVisibleColumns(currentArray);
              }}
            />
          </td>
          <td className="label">
            {element.fieldName === 'StudyDate' ? (
              <label htmlFor={`inputCheckbox${element.fieldName}`}>
                Study Date
              </label>
            ) : (
              <label htmlFor={`inputCheckbox${element.fieldName}`}>
                {element.displayText}
              </label>
            )}
          </td>
        </tr>
      </>
    );
  }

  function ListHiddenItem(element) {
    return (
      <>
        <tr className="eachitems">
          <td>
            <input
              type="checkbox"
              className="inputCheckbox inputbox"
              name={`inputCheckbox${element.fieldName}`}
              value={element.fieldName}
              onChange={() => {
                const currentArray = [];
                hiddenColumns.map(item => {
                  if (item.content.fieldName === element.fieldName) {
                    item.ischecked = !item.ischecked;
                  }
                  currentArray.push(item);
                });
                setHiddenColumns(currentArray);
              }}
            />
          </td>
          <td className="label">
            {element.fieldName === 'StudyDate' ? (
              <label htmlFor={`inputCheckbox${element.fieldName}`}>
                Study Date
              </label>
            ) : (
              <label htmlFor={`inputCheckbox${element.fieldName}`}>
                {element.displayText}
              </label>
            )}
          </td>
        </tr>
      </>
    );
  }

  function Show() {
    return (
      <div className="hideOrshow">
        <button
          className="contextMenuButton"
          onClick={() => {
            buttonOnClick(hiddenColumns, 'show');
          }}
        >
          Show
        </button>
      </div>
    );
  }

  function Hide() {
    return (
      <div className="hideOrshow">
        <button
          className="contextMenuButton"
          onClick={() => {
            buttonOnClick(visibleColumns, 'hide');
          }}
        >
          Hide
        </button>
      </div>
    );
  }

  function buttonOnClick(columns, hideOrShow) {
    const arrayData = [];
    columns.map(item => {
      if (item.ischecked) {
        arrayData.push(item.content);
      }
    });
    if (arrayData.length === 0) {
      // alert('Nothing is selected');
      snackbar.show({
        message: 'Nothing is selected',
        type: 'warning',
        style: { backgroundColor: 'rgb(56,149,106)' },
      });
    } else {
      handleClick(false);
      updateTableMetaOrder(field, arrayData, hideOrShow);
    }
  }
  const reference = useRef(null);
  handleRef(reference);
  const scrollLeft = document.querySelector('#study-list-container').scrollLeft;
  const navPanel = document.getElementById('navigation-panel');
  let dropdownSubmenuClassName = 'dropdown-submenu-left';
  let leftPosition = left;
  if (isSideBarExpanded) {
    leftPosition -= navPanel.offsetWidth;
  }
  if (window.innerWidth - left < 155) {
    leftPosition = leftPosition - 155;
  }
  if (window.innerWidth > 315 && window.innerWidth - left < 315) {
    dropdownSubmenuClassName = 'dropdown-submenu-right';
  }
  const contextMenuStyle = {
    top: `${top - 75}px`,
    left: `${leftPosition + scrollLeft}px`,
  };
  return (
    <div className="contextMenu" style={contextMenuStyle} ref={reference}>
      <ul className="dropdown show">
        {contextMenuItems.map(menu => {
          const depthLevel = 1;
          return (
            <>
              <MenuItems
                items={menu}
                depthLevel={depthLevel}
                dropdownSubmenuClassName={dropdownSubmenuClassName}
              />
            </>
          );
        })}
      </ul>
    </div>
  );
}

RightClickMenu.propTypes = {
  top: PropTypes.number,
  left: PropTypes.number,
  field: PropTypes.object,
  updateTableMeta: PropTypes.func,
  updateTableMetaOrder: PropTypes.func,
  handleClick: PropTypes.func,
  handleRef: PropTypes.func,
  tableMeta: PropTypes.array,
  isSideBarExpanded: PropTypes.bool,
};

export default RightClickMenu;
