import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Dropdown, AboutContent, withModal } from '@ohif/ui';
//
import { UserPreferences } from './../UserPreferences';
import OHIFLogo from '../OHIFLogo/OHIFLogo.js';
import './Header.css';
import { useHistory } from 'react-router-dom';
import ErrorPopupModal from '../ErrorPopupModal/ErrorPopupModal';
import { Icon } from '../../../../ui/src/elements/Icon';
import moment from 'moment';
import Profile from '../Profile/Profile';
import { isMobile } from 'react-device-detect';
import UiModal from '../../../../viewer/src/components/UiModal/UiModal.js';
import CusModal from '../../../../viewer/src/studylist/CusModal.js';
import { AuthInfoBase } from '../../AuthInfo.share.js';
function Header(props) {
  const {
    isTabClosed,
    closeChildTab,
    t,
    user,
    userId,
    userManager,
    modal: { show },
    //useLargeLogo,
    useLogo,
    linkPath,
    linkText,
    location,
    children,
    patientDetails,
    isViewerWithAccno,
    licenseDetails,
    userInfo,
    reportViewer
  } = props;

  const [options, setOptions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [settingModalDisplay, setSettingModalDisplay] = useState(false);
  const hasLink = linkText && linkPath;
  const hasPatientDetails = patientDetails;
  const redirect = useHistory();

  const logout = async () => {
    var idtoken = JSON.parse(
      sessionStorage.getItem(
        'oidc.user:' +
        window.config.oidc[0].authority +
        ':' +
        window.config.oidc[0].client_id
      )
    );
    const accessToken = idtoken.id_token;
    // await closeChildTab(true);
    await userManager.removeUser();
    await userManager.signoutRedirect({ id_token_hint: accessToken });
    localStorage.clear();
  };

  if (isTabClosed) {
    logout();
  }


  useEffect(() => {
    const optionsValue = [
      // {
      //   title: t('Preferences'),
      //   icon: {
      //     name: 'user',
      //   },
      //   onClick: () =>
      //     show({
      //       content: UserPreferences,
      //       title: t('User Preferences'),
      //     }),
      // },
      // {
      //   title: t('ConfigurationModule'),
      //   icon: {
      //     name: 'cog',
      //   },
      //   onClick: () => redirect.push('/configuration-module'),
      // },
      // {
      //   title: t('Error'),
      //   icon: {
      //     name: 'cog',
      //   },
      //   onClick: () => setIsOpen(true),
      // },
    ];
    if (!reportViewer) {
      optionsValue.push({
        title: t('About'),
        icon: { name: 'info' },
        onClick: () =>
          show({
            content: AboutContent,
            contentProps: {
              licenseDetails: licenseDetails,
              userInfo: userInfo
            },
            title: t('About'),
          }),
      });
      optionsValue.push(
        {
          title: t('Settings'),
          icon: { name: 'user' },
          onClick: () => {
            setSettingModalDisplay(true);
          },
        });
    }
    if (hasPatientDetails) {
      optionsValue.push({
        title: t('Preferences'),
        icon: {
          name: 'user',
        },
        onClick: () =>
          show({
            content: UserPreferences,
            title: t('User Preferences'),
          }),
      });
    }
    if (!isMobile && !reportViewer) {
      optionsValue.push({
        title: t('Templates'),
        icon: { name: 'report' },
        onClick: () => {
          const reportURL = `${window.config.servers.dicomWeb[0].reportRoot
            }/view/Template/ReportTemplateList?currentuser=${userId}`;
          window.open(reportURL, '_blank');
        },
      });
    }
    if (hasPatientDetails && !isViewerWithAccno) {
      optionsValue.push({
        title: t('Close'),
        icon: { name: 'power-off' },
        onClick: () => {
          window.close();
        },
      });
    }

    if (window.config.servers.dicomWeb[0].isLocallyInstalled && !reportViewer) {
      optionsValue.push({
        title: t('Reset Password'),
        icon: { name: 'lock' },
        onClick: () => {
          redirect.push(`/reset-password`);
        },
      });
    }

    if (user && userManager && !(hasPatientDetails && !isViewerWithAccno)) {
      optionsValue.push({
        title: t('Logout'),
        icon: { name: 'power-off' },
        onClick: async () => {
          var idtoken = JSON.parse(
            sessionStorage.getItem(
              'oidc.user:' +
              window.config.oidc[0].authority +
              ':' +
              window.config.oidc[0].client_id
            )
          );
          const url =
            window.config.servers.dicomWeb[0].reportRoot +
            '/ReportApi/Logout';
          const accessToken = idtoken.id_token;
          if (closeChildTab) await closeChildTab(true);
          try {
            const bearerToken = 'bearer ' + accessToken;
            const response = await fetch(url,
              {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: bearerToken,
                },
              }
            );
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            var wind = window.open(response.url);
          } catch (error) {
            console.error('There was a problem with the logout:', error);
          }
          await userManager.removeUser();
          await userManager.signoutRedirect({ id_token_hint: accessToken });
          wind.close();
        },
      });
    }

    setOptions(optionsValue);
  }, [
    redirect,
    setOptions,
    show,
    t,
    user,
    userManager,
    hasPatientDetails,
    closeChildTab,
  ]);

  const calculateAge = dob => {
    if (!dob) {
      return <></>;
    }
    var today = new Date();
    var birthdate = new Date(moment(dob, 'YYYYMMDD').format('MMM DD, YYYY'));
    var age_time = today.getTime() - birthdate.getTime(); // in milliseconds
    var years = Math.floor(age_time / 31556736000); // convert to years
    var days_diff = Math.floor((age_time % 31556736000) / 86400000);
    var months = Math.floor(days_diff / 30.4167);
    if (years < 1) {
      if (isMobile) {
        return <>&nbsp;{months} M,</>;
      }
      return <>&nbsp;{months} months,</>;
    } else if (years <= 3) {
      if (isMobile) {
        return (
          <>
            &nbsp;{years} Y {months} M,&nbsp;
          </>
        );
      }
      return (
        <>
          &nbsp;{years} years {months} months,&nbsp;
        </>
      );
    } else {
      if (isMobile) {
        return <>&nbsp;{years} Y </>;
      }
      return <>&nbsp;{years} years,&nbsp;</>;
    }
  };

  const gender = patientSex => {
    patientSex = patientSex ? patientSex.toUpperCase() : '';
    if (patientSex === 'F') {
      return <Icon name="female" />;
    } else if (patientSex === 'M') {
      return <Icon name="male" />;
    } else {
      return <></>;
    }
  };
  const patientname = patientname => {
    if (isMobile) {
      if (patientname.length > 11) {
        patientname = patientname.substring(0, 15).concat('...');
        return patientname.replace(/\^/g, ' ');
      }
    } else {
      return patientname.replace(/\^/g, ' ');
    }
  };

  return (
    <>
      {/* <div className="notification-bar">{t('INVESTIGATIONAL USE ONLY')}</div> */}
      <div className={classNames('entry-header', { 'header-logo': useLogo })}>
        <div className="header-left-box">
          {location && location.studyLink && (
            <Link
              to={location.studyLink}
              className="header-btn header-viewerLink"
            >
              {t('Back to Viewer')}
            </Link>
          )}

          {children}

          {hasLink && (
            <Link
              className="header-btn header-studyListLinkSection"
              to={{
                pathname: linkPath,
                state: { studyLink: location.pathname },
              }}
            >
              {t(linkText)}
            </Link>
          )}
          {hasPatientDetails && (
            <>
              {/* PATIENT HEADER */}
              <div className="header-patientDetails">
                {patientDetails.patientSex && (
                  <span>{gender(patientDetails.patientSex)}</span>
                )}
                {patientDetails.PatientName && (
                  <span style={{ fontWeight: 400 }}>
                    {patientname(patientDetails.PatientName)},
                  </span>
                )}
                {/* <div className='header-dob&Id'> */}
                {patientDetails.PatientBirthdate && (
                  <span>{calculateAge(patientDetails.PatientBirthdate)}</span>
                )}
                {patientDetails.PatientID && (
                  <span className="patientID">
                    Patient ID : {patientDetails.PatientID}
                  </span>
                )}
                {/* </div>   */}
              </div>
            </>
          )}
        </div>

        <div className="header-menu">
          {/* <span className="research-use">{t('INVESTIGATIONAL USE ONLY')}</span> */}
          <Dropdown
            title={
              !isMobile || !useLogo
                ? user.profile.name
                : ''
            }
            list={options}
            align="right"
          />
        </div>
      </div>
      {settingModalDisplay && (
        <CusModal>
          <UiModal
            modalSize={'sm'}
            onClose={() => {
              setSettingModalDisplay(false);
            }}
            title="Settings"
            footerVisibility={false}
          >
            <Profile />
          </UiModal>
        </CusModal>
      )}
      <ErrorPopupModal
        isOpen={isOpen}
        updateisOPen={setIsOpen}
        title="ERROR"
        content="page not found"
        buttonText="Close"
      ></ErrorPopupModal>
    </>
  );
}

Header.propTypes = {
  // Study list, /
  linkText: PropTypes.string,
  linkPath: PropTypes.string,
  useLargeLogo: PropTypes.bool,
  useLogo: PropTypes.any,
  isTabClosed: PropTypes.bool,
  isViewerWithAccno: PropTypes.bool,
  closeChildTab: PropTypes.func,
  //
  location: PropTypes.object.isRequired,
  children: PropTypes.node,
  t: PropTypes.func.isRequired,
  userManager: PropTypes.object,
  user: PropTypes.object,
  modal: PropTypes.object,
  patientDetails: PropTypes.object,
  licenseDetails: PropTypes.object,
};

Header.defaultProps = {
  useLargeLogo: false,
  children: OHIFLogo(),
};

export default withTranslation(['Header', 'AboutModal'])(
  withRouter(withModal(Header))
);
