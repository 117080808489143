import React from 'react';
import PropTypes from 'prop-types';
import './InputBox.styl';

export default function InputBox(props) {
  const {
    className,
    name,
    type,
    placeholder,
    value,
    labelName,
    isdisabled,
    inputFieldClassName,
    labelClassName,
    OnChange,
  } = props;
  return (
    <div className="inputBoxWholeContainer">
      <div className={`${className} inputboxContainer`} id={`${name}Container`}>
        <label
          htmlFor={`${name}Input`}
          id={`${name}Label`}
          className={labelClassName}
        >
          {labelName}
        </label>
        <input
          type={type}
          name={name}
          placeholder={placeholder}
          disabled={isdisabled}
          value={value}
          id={`${name}Input`}
          className={inputFieldClassName}
          onChange={e => OnChange(e)}
        />
      </div>
    </div>
  );
}

InputBox.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  labelName: PropTypes.string,
  isdisabled: PropTypes.bool,
  inputFieldClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  OnChange: PropTypes.func,
};
