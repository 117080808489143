import React from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
Modal.setAppElement('#root');
export function ModalContent({ isOpen, updateisOpen, title, content, style }) {
  document.onkeydown = event => {
    event = event || window.event;
    if (event.key === 'Escape') {
      updateisOpen(false);
    }
  };
  return (
    <Modal
      isOpen={isOpen}
      style={
        style
          ? style
          : {
              overlay: {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                // top: 0,
                // left: 0,
                // right: 0,
                // bottom: 0,
                backgroundColor: 'rgba(25,41,40,0.8)',
              },
              content: {
                position: 'relative',
                flexDirection: 'column',
                maxHeight: '90vh',
                maxWidth: '90%',
                // top: '20%',
                // bottom: '20%',
                // left: '20%',
                // right: '20%',
                width: !isMobile ? '1000px' : '65%',
                height: !isMobile ? '500px' : '30%',
                border: '1px solid rgba(255, 255, 255, 0.759)',
                color: '#fff',
                overflow: 'auto',
                WebkitOverflowScrolling: 'touch',
                borderRadius: '6px',
                outline: 'none',
                padding: '10px',
                backgroundColor: '#12221f',
              },
            }
      }
    >
      <div className="modal-header">
        <h2>{title}</h2>
        <button
          onClick={() => {
            updateisOpen(false);
          }}
        >
          X
        </button>
      </div>
      {content}
    </Modal>
  );
}

ModalContent.propTypes = {
  isOpen: PropTypes.bool,
  updateisOpen: PropTypes.func,
  title: PropTypes.string,
  content: PropTypes.any,
  style: PropTypes.object,
};
export default ModalContent;
