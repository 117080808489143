import React, { useEffect, useRef, useState } from 'react';
import './Profile.css';
import axios from 'axios';
import { useSnackbarContext } from '../../../../ui/src/contextProviders';

function Profile() {
  const [designation, setDesignation] = useState('');
  const [qualification, setQualification] = useState('');
  const [fileName, setFileName] = useState('');
  const [jpgSignatureFile, setJpgSignatureFile] = useState();
  const BaseURL = window.config.servers.dicomWeb[0].wadoRoot;
  const snackBar = useSnackbarContext();
  const inputref = useRef();
  const containsSpecialChars = str => {
    const specialChars = /[`!@#$%^&*()+\=\[\]{};':"\\|,.<>\/?~]/;
    return specialChars.test(str);
  };
  const [isMobileDefault, setIsMobileDefault] = useState('N');

  const submitHandler = async e => {
    e.preventDefault();
    if (
      containsSpecialChars(designation) ||
      containsSpecialChars(qualification)
    ) {
      snackBar.show({
        message: 'Contains special charecters',
        type: 'warning',
      });
      return;
    }
    if (designation.trim().length === 0 || qualification.trim().length === 0) {
      snackBar.show({
        message: 'Empty field',
        type: 'warning',
      });
      inputref.current.value = null;
      return;
    } else if (fileName === '') {
      snackBar.show({
        message: 'Signature is not uploaded',
        type: 'warning',
      });
      inputref.current.value = null;
    } else {
      const url = `${BaseURL}signatureupload/`;
      let data = null;
      if (jpgSignatureFile) {
        data = new FormData();
        data.append('file', jpgSignatureFile ? jpgSignatureFile : null);
        data.append('fileName', jpgSignatureFile ? jpgSignatureFile.name : '');
      }
      const idtoken = JSON.parse(
        sessionStorage.getItem(
          'oidc.user:' +
            window.config.oidc[0].authority +
            ':' +
            window.config.oidc[0].client_id
        )
      );
      const bearerToken = 'bearer ' + idtoken.id_token;
      await axios
        .post(url, data, {
          params: {
            designation: designation,
            qualification: qualification,
            ismobiledefault: isMobileDefault,
          },
          headers: {
            'Content-Type': 'image/jpeg',
            Authorization: bearerToken,
          },
        })
        .then(async res => {
          if (res.status === 200) {
            snackBar.show({
              message: 'Submitted',
              type: 'success',
            });
          }
        })
        .catch(err => console.error(err));
      inputref.current.value = null;
    }
  };

  const fileChangeHandler = () => {
    if (
      inputref.current.files[0].type === 'image/jpeg' ||
      inputref.current.files[0].type === 'image/jpg'
    ) {
      if (inputref.current.files[0].size > 102400) {
        snackBar.show({
          message: 'Maximum File Size (100KB) exceeded.',
          type: 'warning',
        });
        inputref.current.value = null;
        return;
      }
      var img = new Image();
      var imgWidth, imgHeight;
      img.src = window.URL.createObjectURL(inputref.current.files[0]);
      img.onload = () => {
        imgWidth = img.width;
        imgHeight = img.height;
        if (imgWidth > 200 || imgHeight > 200) {
          snackBar.show({
            message: 'Image should have Max Width 200px and Max Height 200px.',
            type: 'warning',
          });
          inputref.current.value = null;
          return;
        }
        setJpgSignatureFile(inputref.current.files[0]);
        setFileName(inputref.current.files[0].name);
      };
    } else {
      snackBar.show({
        message: 'Only jpeg/jpg is allowed',
        type: 'warning',
      });
      inputref.current.value = null;
      return;
    }
  };

  const fetchUserDetails = () => {
    const url = `${BaseURL}fetchuserdetails/`;
    const idtoken = JSON.parse(
      sessionStorage.getItem(
        'oidc.user:' +
          window.config.oidc[0].authority +
          ':' +
          window.config.oidc[0].client_id
      )
    );
    const bearerToken = 'bearer ' + idtoken.id_token;
    axios
      .post(url, null, {
        headers: { Authorization: bearerToken },
      })
      .then(res => {
        if (res.status === 200) {
          setDesignation(res.data.designation);
          setQualification(res.data.qualification);
          setFileName(res.data.fileName);
          setIsMobileDefault(res.data.mobileView.toUpperCase());
        }
      })
      .catch(err => console.error(err));
  };
  useEffect(() => {
    fetchUserDetails();
  }, []);
  return (
    <>
      <form onSubmit={submitHandler} action="">
        <div className="settings-modal-container">
          <div className="settings-input-label-div">
            <label htmlFor="designation">Designation</label>
            <input
              className="settting-input ui-input"
              type="text"
              id="designation"
              maxLength={30}
              value={designation}
              onChange={e => {
                setDesignation(e.target.value);
              }}
              placeholder="Max char (30)"
            />
          </div>
          <div className="settings-input-label-div">
            <label>Qualification</label>
            <input
              className="settting-input ui-input"
              type="text"
              maxLength={30}
              value={qualification}
              onChange={e => {
                setQualification(e.target.value);
              }}
              placeholder="Max char (30)"
            />
          </div>
          <div className="settings-input-label-div">
            <label>Upload Signature ( Maximum size 100 KB )</label>
            <label htmlFor="upload-file" className="upload-file-label">
              Signature
            </label>
            <input
              className="settings-upload-file"
              type="file"
              name="file"
              id="upload-file"
              accept=".jpg"
              ref={inputref}
              onChange={fileChangeHandler}
            />
          </div>
          <div className="mobile-view">
            <span>Enable Mobile View</span>
            <label className="switch">
              <input
                type="checkbox"
                checked={isMobileDefault == 'Y' ? true : false}
                onChange={() =>
                  setIsMobileDefault(isMobileDefault == 'N' ? 'Y' : 'N')
                }
              />
              <span className="slider round"></span>
            </label>
          </div>
          <div className="submit-button-div">
            <button className="settings-submit-button" type="submit">
              Submit
            </button>
          </div>
        </div>
      </form>
    </>
  );
}

export default Profile;
