import React from 'react';
import './StatusContent.css';

function ReportStatus() {
  const reportStatus = JSON.parse(
    sessionStorage.getItem('X-Report-Status-Summary')
  ) || { noReport: 0, drafted: 0, preliminary: 0, finalized: 0 };
  const count = [
    {
      item: 'No Report',
      count: reportStatus.noReport,
      style: {
        backgroundColor: 'rgba(195, 90, 90, 0.923)',
      },
    },
    {
      item: 'Drafted',
      count: reportStatus.drafted,
      style: {
        backgroundColor: 'rgba(149, 149, 65, 0.931)',
      },
    },
    {
      item: 'Preliminary',
      count: reportStatus.preliminary,
      style: {
        backgroundColor: 'rgba(65, 90, 143, 0.923)',
      },
    },
    {
      item: 'Finalized',
      count: reportStatus.finalized,
      style: {
        backgroundColor: 'rgba(91, 138, 91, 0.923)',
      },
    },
  ];

  return (
    <>
      <div className="statusContainer">
        <div className="head">
          <span>Report Status</span>
        </div>
        {count.map((element, i) => {
          return (
            <div className="report-status" style={element.style} key={`${element.item}-${i}`}>
              <span>{element.item} : </span>
              <span>{element.count}</span>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default ReportStatus;
