import React from 'react';
import PropTypes from 'prop-types';
import WhiteLabelingContext from '../../context/WhiteLabelingContext';
import UserManagerContext from '../../context/UserManagerContext';
import ConnectedHeader from '../../connectedComponents/ConnectedHeader';

import './HeaderComponent.styl';

export default function HeaderComponent(props) {
  const { user, headerName, SubmitClick, CancelClick } = props;
  return (
    <div className="headerComponent">
      {/* Header */}
      <WhiteLabelingContext.Consumer>
        {whiteLabeling => (
          <UserManagerContext.Consumer>
            {userManager => (
              <ConnectedHeader user={user} userManager={userManager}>
                {whiteLabeling &&
                  whiteLabeling.createLogoComponentFn &&
                  whiteLabeling.createLogoComponentFn(React)}
              </ConnectedHeader>
            )}
          </UserManagerContext.Consumer>
        )}
      </WhiteLabelingContext.Consumer>
      <div className="sub-header">
        <div className="header">{headerName}</div>
        <div className="actions">
          <button className="headerActionButtons" onClick={SubmitClick}>
            Submit
          </button>
          <button className="headerActionButtons" onClick={CancelClick}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

HeaderComponent.propTypes = {
  user: PropTypes.string,
  headerName: PropTypes.string,
  SubmitClick: PropTypes.func,
  CancelClick: PropTypes.func,
};
