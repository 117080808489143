import React, { useState } from 'react';
import Icon from '../../elements/Icon/Icon';
import './DocTable.css';
import PropTypes from 'prop-types';

function DocTable({ documents, downloadDoc, deleteDoc, deletedElement }) {
  const [disableDeletedFile, setDisableDeletedFile] = useState(null);
  function formatDisplayTime(dateTime) {
    const adjustedDateTime = new Date(dateTime);
    const offset = adjustedDateTime.getTimezoneOffset();
    const sign = offset > 0 ? -1 : 1;
    const delayMinutes = Math.abs(offset) * sign;
    adjustedDateTime.setMinutes(adjustedDateTime.getMinutes() + delayMinutes);

    return adjustedDateTime.toLocaleString('en-US', {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
    });
  }
  return (
    <div>
      <table className="document-details-table">
        <thead>
          <tr>
            <th>Action</th>
            <th>Document Name</th>
            <th>Description</th>
            <th>Attached Date</th>
            <th>Created By</th>
          </tr>
          {documents &&
            documents.length > 0 &&
            documents
              .sort(
                (a, b) =>
                  new Date(new Date(b.uploadDate)) -
                  new Date(new Date(a.uploadDate))
              )
              .map((row, index) => {
                return (
                  <tr
                    key={`${index}_${row.fileName}`}
                    id={row.fileName}
                    className={
                      deletedElement === row.fileName
                        ? 'deleting-element'
                        : 'doc-element'
                    }
                  >
                    <td>
                      <button
                        className="doc-action-button"
                        title="Document View"
                        onClick={() => {
                          downloadDoc(index);
                        }}
                      >
                        <Icon name="download" />
                      </button>
                      <button
                        className="doc-action-button"
                        title="Delete"
                        disabled={disableDeletedFile === row.fileName}
                        onClick={() => {
                          if (disableDeletedFile !== row.fileName)
                            deleteDoc(row.fileName);
                          setDisableDeletedFile(row.fileName);
                        }}
                      >
                        <Icon name="deleted" />
                      </button>
                    </td>
                    <td>{row.fileName}</td>
                    <td>{row.description}</td>
                    {/* <td>{row.docType}</td> */}
                    <td>{formatDisplayTime(row.uploadDate)}</td>
                    <td>{row.createduserName}</td>
                  </tr>
                );
              })}
        </thead>
      </table>

      {documents.length === 0 && (
        <h4 className="message-nodoc">No documents found for the study</h4>
      )}
    </div>
  );
}

DocTable.propTypes = {
  documents: PropTypes.array,
  downloadDoc: PropTypes.func,
  deleteDoc: PropTypes.func,
  deletedElement: PropTypes.string,
};

export default DocTable;
