import React, { useEffect, useRef, useState } from 'react';
import './LicenseConfiguration.css';
import { Icon } from '@ohif/ui';
import { useSnackbarContext } from '@ohif/ui';
// import { CopyToClipboard } from 'react-copy-to-clipboard';
import PropTypes from 'prop-types';

const LicenseConfiguration = props => {
  const {
    licenseDetails,
    setLicenseDetails,
    setLicenseConfiguration,
    setOpenWorklist,
    cancelButton,
  } = props;
  const [textArea, setTextArea] = useState('');
  const [disableContinueButton, setDisableContinueButton] = useState(true);
  const licenseFileRef = useRef();
  const snackbar = useSnackbarContext();
  const [productKey, setProductKey] = useState('');
  const [productIdCopied, setProductIdCopied] = useState('');
  const BaseURL = window.config.servers.dicomWeb[0].wadoRoot;

  //handling the file selection and extracting the text from the select file
  const handleFileInputChange = async event => {
    const file = event.target.files[0];
    if (file.name.endsWith('.lic') || file.type === '.lic') {
      let fileReader = new FileReader();
      fileReader.onload = async e => {
        const text = e.target.result;
        setTextArea(text);
      };
      fileReader.readAsText(file);
      fileReader.onerror = () => {
        alert('wrong type');
        // setLicenseKey('');
      };
    } else {
      snackbar.show({
        message:
          'Sorry, the file type you are trying to upload is not supported.',
        type: 'warning',
      });
    }
  };

  //method for the license registration
  const Register = () => {
    if (textArea === '') {
      setDisableContinueButton(true);
      return snackbar.show({
        message: 'Enter license key to proceed.',
        type: 'warning',
      });
    } else {
      var myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');

      var raw = JSON.stringify({
        centerID: 'CENTER',
        productKey: textArea,
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow',
      };
      fetch(`${BaseURL}License/ApplyLicense`, requestOptions)
        .then(response => response.json())
        .then(result => {
          setLicenseDetails(result);
          if (result.isLicensed) setDisableContinueButton(false);
        })
        .catch(error => console.log('error', error));
    }
    return;
  };

  //fetching the product Id
  useEffect(() => {
    var requestOptions = {
      method: 'GET',
    };

    fetch(`${BaseURL}License/productID`, requestOptions)
      .then(response => response.json())
      .then(result => setProductKey(result))
      .catch(error => console.log('error', error));
  }, []);
  const CopyToClipboard = () => {
    setProductIdCopied(navigator.clipboard.writeText(productKey.productID));
    if (productIdCopied != '') {
      snackbar.show({
        message: 'Copied.',
        type: 'success',
      });
    }
  };
  const ContinueButtonClick = () => {
    setLicenseConfiguration(false);
    setOpenWorklist && setOpenWorklist(true);
  };
  return (
    <>
      <div className="theWholeComponent">
        <div className="logoDiv">
          <div className='licenseConfigurationIconDiv'>
            <Icon className="logo" name="fuji-logo"></Icon>
            <div className="cpacs">PACS</div>
          </div>
          {cancelButton && <button className='licenseConfigurationClose' data-cy="close-button" onClick={ContinueButtonClick}>X</button>}
        </div>
        <div className="fromConfiguration">
          <h2>Configuration</h2>
          <h3>License</h3>
          <h5>status : {licenseDetails.statusText}</h5>
          <form>
            <label>Product ID:</label>
            <div className="productIdInputcopyButton">
              <div className="productIdInput">
                <input
                  className="licenseProductIDInput"
                  label="Product ID"
                  type="text"
                  value={productKey.productID}
                />
                <button
                  className="licenseCopyButton"
                  type="button"
                  title="copy"
                  onClick={() => {
                    CopyToClipboard();
                  }}
                >
                  <Icon name="copy" />
                </button>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'baseline',
              }}
            >
              <label>License Key :</label>
              <label htmlFor="files" className="licenseConfigurationBtn">
                Upload License file
              </label>
              <input
                type="file"
                id="files"
                ref={licenseFileRef}
                onChange={handleFileInputChange}
                multiple
                hidden
              />
            </div>
            <textarea
              name="license"
              id="license"
              cols="40"
              rows="5"
              value={textArea}
              onChange={e => setTextArea(e.target.value)}
            ></textarea>
          </form>
          <div className="licenseConfigurationbuttons">
            <input
              type="button"
              className="Continue"
              disabled={disableContinueButton}
              value="Continue"
              onClick={() => ContinueButtonClick()}
            />
            <input
              type="button"
              className="Register"
              value="Register"
              onClick={() => Register()}
            />
          </div>
        </div>
      </div>
    </>
  );
};
LicenseConfiguration.propTypes = {
  licenseDetails: PropTypes.string.isRequired,
  setLicenseDetails: PropTypes.func,
  setLicenseConfiguration: PropTypes.func,
  licenseConfiguration: PropTypes.string.isRequired,
  setOpenWorklist: PropTypes.func,
  openWorklist: PropTypes.bool.isRequired,
};
export default LicenseConfiguration;
