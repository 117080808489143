import React from 'react';
import PropTypes from 'prop-types';
import './loader.styl';

function Loader({ classname = '' }) {
  return <div className={`loader ${classname}`}></div>;
}
Loader.propTypes = {
  classname: PropTypes.string,
};

export default Loader;
