import React from 'react';
import PropTypes from 'prop-types';
import './WarningModal.css';
import { Icon } from '@ohif/ui';
function WarningModal({ onClose, updateisOpen, message }) {
  document.onkeydown = event => {
    event = event || window.event;
    if (event.key === 'Escape') {
      updateisOpen(false);
    }
  };
  const closeModal = () => {
    if (onClose) onClose();
    if (updateisOpen) updateisOpen(false);
  };
  return (
    <div className="modal-overlay-warning" onClick={closeModal}>
      <div className="modal-content-warning">
        {message}
        <button className="modal-close-button-warning" onClick={closeModal}>
          <Icon name="close" />
        </button>
      </div>
    </div>
  );
}
WarningModal.propTypes = {
  title: PropTypes.string,
  onClose: PropTypes.func,
  message: PropTypes.string,
  updateisOpen: PropTypes.func,
};

export default WarningModal;
