import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ErrorPage } from '@ohif/ui';
import './AssignToUserContent.css';
import { Icon } from '@ohif/ui';

function AssignToUserContent({ updateAssigntoOpen, studyData }) {
  const [userData, setUserData] = useState([]);
  // assigned user array
  const [assignedUserData, setAssignedUserData] = useState([]);

  // used to set error on api calls
  const [error, setError] = useState(false);
  const [errorDetails, setErrorDetails] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  useEffect(() => {
    // getting user data from the api
    const getUserData = async () => {
      var headerData = new Headers();
      var idtoken = JSON.parse(
        sessionStorage.getItem(
          'oidc.user:' +
            window.config.oidc[0].authority +
            ':' +
            window.config.oidc[0].client_id
        )
      );
      var bearerToken = 'bearer ' + idtoken.id_token;
      headerData.append('Content-Type', 'application/json');
      headerData.append('Access-Control-Allow-Origin', '*');
      headerData.append('Authorization', bearerToken);

      var request = {
        method: 'POST',
        headers: headerData,
        body: '',
        redirect: 'follow',
      };

      let errordetails = {};

      await fetch(
        `${window.config.servers.dicomWeb[0].apiRoot}/api/assignuser/fetch/${studyData[0].StudyInstanceUID}/`,
        request
      )
        .then(response => {
          if (response.status === 200 || response.status === 400) {
            setError(false);
            return response.json();
          } else {
            setError(true);
            errordetails = {
              title: response.statusText,
              description: '',
            };
            return response.text();
          }
        })
        .then(result =>
          typeof result === 'string'
            ? setErrorDetails({ ...errordetails, description: result })
            : setUserData(result)
        )
        .catch(error => console.error('error', error));
    };
    getUserData();
  }, []);

  useEffect(() => {
    let temp = [];
    userData.map(item => {
      if (item.isAssigned === 'Y') {
        temp.push(item.userId);
      }
    });
    setAssignedUserData(temp);
  }, [userData]);

  const postUserData = async postbody => {
    var headerData = new Headers();
    var idtoken = JSON.parse(
      sessionStorage.getItem(
        'oidc.user:' +
          window.config.oidc[0].authority +
          ':' +
          window.config.oidc[0].client_id
      )
    );
    var bearerToken = 'bearer ' + idtoken.id_token;
    headerData.append('Content-Type', 'application/json');
    headerData.append('Access-Control-Allow-Origin', '*');
    headerData.append('Authorization', bearerToken);

    var postBody = JSON.stringify(postbody);

    var request = {
      method: 'POST',
      headers: headerData,
      body: postBody,
      redirect: 'follow',
    };
    let errordetails = {};
    await fetch(
      `${window.config.servers.dicomWeb[0].apiRoot}/api/assignstudy/`,
      request
    )
      .then(response => {
        if (response.status === 200) {
          setError(false);
          updateAssigntoOpen(false);
          var x = document.getElementById('snackbar');
          x.className = 'show success';
          x.innerHTML = 'Saved';
          setTimeout(function() {
            x.className = x.className.replace('show success', '');
          }, 3000);
        } else {
          setError(true);
          errordetails = {
            title: response.statusText,
            description: '',
          };
          return response.text();
        }
      })
      .then(result => setErrorDetails({ ...errordetails, description: result }))
      .catch(error => console.error('error', error));
  };

  if (error) {
    return (
      <ErrorPage
        error={errorDetails.error}
        title={errorDetails.title}
        description={errorDetails.description}
        // onRetry={() => {}}
      />
    );
  }

  const filteredUserData = userData.filter(user =>
    user.userName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <div className="assign-to">
        <div className="searchbar-user">
          <button>
            <Icon name="search" />
          </button>
          <input
            type="search"
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
          />
        </div>
        <div className="assign-to-body">
          {filteredUserData && filteredUserData.length > 0 ? (
            filteredUserData
              .sort((a, b) => {
                if (
                  assignedUserData.includes(a.userId) ===
                  assignedUserData.includes(b.userId)
                ) {
                  return a.userName + a.userId > b.userName + b.userId ? 1 : -1;
                }
                return assignedUserData.includes(a.userId) ? -1 : 1;
              })
              .map(item => (
                <div className="select-user" key={item.userId}>
                  <input
                    className="select-user-checkbox"
                    type="checkbox"
                    name={item.userId}
                    id={item.userId}
                    checked={assignedUserData.includes(item.userId)}
                    onChange={() => {
                      if (assignedUserData.includes(item.userId)) {
                        setAssignedUserData(
                          assignedUserData.filter(user => user !== item.userId)
                        );
                      } else {
                        setAssignedUserData([...assignedUserData, item.userId]);
                      }
                    }}
                  />
                  <label htmlFor={item.userId}>{item.userName}</label>
                </div>
              ))
          ) : (
            <>{'No Data Found'}</>
          )}
        </div>
        <div className="assign-to-footer">
          <p
            style={{
              display: assignedUserData.length <= 10 ? 'none' : 'block',
            }}
          >
            {"Can't select more than 10 users"}
          </p>
          <button
            className="study-info-btn"
            disabled={assignedUserData.length > 10}
            onClick={() => {
              if (assignedUserData.length <= 10) {
                studyData.map(async study => {
                  const postbody = {
                    studyInstanceUid: study.StudyInstanceUID,
                    users: assignedUserData,
                  };
                  await postUserData(postbody);
                });
              }
            }}
          >
            Save
          </button>
        </div>
      </div>
    </>
  );
}

AssignToUserContent.propTypes = {
  studyData: PropTypes.array,
  updateAssigntoOpen: PropTypes.func,
};

export default AssignToUserContent;
