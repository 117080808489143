import React from 'react';
// import detect from 'browser-detect';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import LisenceConfiguration from '../../licenseConfiguration/LicenseConfiguration'
import './AboutContent.styl';

import { Icon } from '@ohif/ui';

const AboutContent = ({ licenseDetails,userInfo }) => {
  const { t } = useTranslation('AboutContent');
  const [licenseConfiguration,setLicenseConfiguration] = React.useState(false)
  const [license,setLicense] = React.useState({...licenseDetails})
  const handleLicenseClick = ()=>{
    setLicenseConfiguration(true);
  }
  const isMobile = window.innerWidth <= 768;
  return (
    <>
      {licenseConfiguration && 
      <div style={{position:'fixed',top:'0',left:'0'}}>
        <LisenceConfiguration
        licenseDetails={license}
        setLicenseDetails={setLicense}
        setLicenseConfiguration={setLicenseConfiguration}
        cancelButton={true}
        />
          </div>
          }
    <div className="AboutContent" data-cy="about-modal">
      <div className="about-info">
        <div className="header">
          <Icon name="fuji-logo" className="header-logo-text" />
          <div className="logo-text">PACS</div>
        </div>
        <div className="heading">
          <h3>{t('Version - 1.2.1.5')}</h3>
          {window.config.servers.dicomWeb[0].isLocallyInstalled && (
            <div className="license-details">
              <div className="license-detail">
                <span className="license-label">{'License Serial Number'}</span>
                <span className="license-value">{' : '}{license.licenseSerialNumber}</span>
              </div>
              <div className="license-detail">
                <span className="license-label">{'License Status'}</span>
                <span className="license-value">{' : '}{license.statusText}</span>
              </div>
            </div>
          )}
        </div>
        <div className="address">
          <p className="title">{t('FUJIFILM INDIA PRIVATE LIMITED')}</p>
          <p className="sub-address">
            Unitech Cyber Park, 8th floor, Tower C, <br />
            Sector 39, Gurugram, India, 122001
          </p>
        </div>
        {!isMobile &&  window.config.servers.dicomWeb[0].isLocallyInstalled && 
         (userInfo && userInfo.account && userInfo.account.IsSuperAdmin) &&
         (
        <div className="AboutContent-buttonDiv">
        <input
              type="button"
              className="Continue"
              value="License"
              onClick={handleLicenseClick}
            />
        </div>)}
      </div>
      
    </div>
    </>
  );
};

AboutContent.propTypes = {
  licenseDetails: PropTypes.object,
};

export { AboutContent };
export default AboutContent;
