import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import './IdleTimerContainer.css';

Modal.setAppElement(document.getElementById('root'));

function IdleTimerContainer(props) {
  // Time out if the user is idle for 10 minutes
  const timeoutInMinutes = 10;
  const timeoutInMilliSeconds = timeoutInMinutes * 60000;
  // const [loggedin, setLoggedin] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [logoutModalOpen, setLogoutModalOpen] = useState(false);
  const checkForInactivity = () => {
    const expireTime = localStorage.getItem('expireTime');
    if (expireTime < Date.now()) {
      setModalIsOpen(true);
    }
  };
  const checkForIntervalExceeds = () => {
    const expireTimeExceeds = localStorage.getItem('expireTimeExceeds');
    if (expireTimeExceeds < Date.now()) {
      setModalIsOpen(false);
      setLogoutModalOpen(true);
      // setLoggedin(false);
      logOut();
    }
  };

  const updateExpireTime = () => {
    const expireTime = Date.now() + timeoutInMilliSeconds;
    localStorage.setItem('expireTime', expireTime);
    localStorage.setItem(
      'expireTimeExceeds',
      expireTime + timeoutInMilliSeconds
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      checkForInactivity();
    }, 1000);

    const intervalExceeds = setInterval(() => {
      checkForIntervalExceeds();
    }, 1000);

    return () => {
      clearInterval(interval);
      clearInterval(intervalExceeds);
    };
  }, []);

  useEffect(() => {
    updateExpireTime();

    window.addEventListener('click', updateExpireTime);
    window.addEventListener('keypress', updateExpireTime);
    window.addEventListener('scroll', updateExpireTime);
    window.addEventListener('mousemove', updateExpireTime);

    return () => {
      window.removeEventListener('click', updateExpireTime);
      window.removeEventListener('keypress', updateExpireTime);
      window.removeEventListener('scroll', updateExpireTime);
      window.removeEventListener('mousemove', updateExpireTime);
    };
  }, []);

  const stayActive = () => {
    setModalIsOpen(false);
    setLogoutModalOpen(false);
    // setLoggedin(true);
  };

  const logOut = async () => {
    var idtoken = JSON.parse(
      sessionStorage.getItem(
        'oidc.user:' +
          window.config.oidc[0].authority +
          ':' +
          window.config.oidc[0].client_id
      )
    );
    const accessToken = idtoken.id_token;
    setModalIsOpen(false);
    setLogoutModalOpen(false);
    // setLoggedin(false);

    //history.push('/logout');
    await props.userManager.removeUser();
    await props.userManager.signoutRedirect({ id_token_hint: accessToken });
  };

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        style={{
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: '#192928',
            zIndex: 9999999,
          },
          content: {
            position: 'absolute',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            top: window.innerWidth > 820 ? '100px' : '18%',
            left: window.innerWidth > 820 ? '150px' : '13%',
            right: window.innerWidth > 820 ? '150px' : '13%',
            bottom: window.innerWidth > 820 ? '100px' : '18%',
            border: '1px solid #ccc',
            color: '#fff',
            overflow: window.innerWidth < 420 ? 'hidden' : 'auto',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '4px',
            outline: 'none',
            padding: window.innerWidth > 820 ? '20px' : '10px',
            backgroundColor: '#12221f',
          },
        }}
      >
        <div className="timeoutSession">
          <h2>{"You've been idle for a while!"}</h2>
          <p>{'You will be logged out soon'}</p>
          <div style={{ display: window.innerWidth < 420 ? 'flex' : '' }}>
            <button onClick={logOut}>{'Log Me Out'}</button>
            <button onClick={stayActive}>{'Keep me Signed In'}</button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={logoutModalOpen}
        style={{
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: '#192928',
            zIndex: 9999999,
          },
          content: {
            position: 'absolute',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            top: window.innerWidth > 820 ? '100px' : '20%',
            left: window.innerWidth > 820 ? '150px' : '20%',
            right: window.innerWidth > 820 ? '150px' : '20%',
            bottom: window.innerWidth > 820 ? '100px' : '20%',
            border: '1px solid #ccc',
            color: '#fff',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '4px',
            outline: 'none',
            padding: window.innerWidth > 820 ? '20px' : '10px',
            backgroundColor: '#12221f',
          },
        }}
      >
        <div>
          <h2>{'LogIn Session Time Out'}</h2>
        </div>
      </Modal>
    </>
  );
}

export default IdleTimerContainer;
