import React from 'react';
import PropTypes from 'prop-types';
import './UiModal.css';
import { Icon, Loader } from '@ohif/ui';
function UiModal({
  title,
  onClose,
  updateisOpen,
  onSave,
  children,
  modalSize,
  footerVisibility,
  closeBtnTitle = 'Close',
  saveBtnTitle = 'Save',
  saveBtnDisable = false,
}) {
  document.onkeydown = event => {
    event = event || window.event;
    if (event.key === 'Escape') {
      updateisOpen(false);
    }
  };
  const closeModal = () => {
    if (onClose) onClose();
    if (updateisOpen) updateisOpen(false);
  };
  return (
    <div className="modal-overlay">
      <div
        className={`modal-container ${modalSize}`}
        onClick={e => e.stopPropagation()}
      >
        <div className="header-container">
          <div>
            <header>
              <h2>{title}</h2>
            </header>
          </div>
          <button
            className="modal-close-button"
            onClick={closeModal}
            disabled={saveBtnDisable}
          >
            <Icon name="close" />
          </button>
        </div>
        <hr />
        <div className={`modal-content ${modalSize}`}>{children}</div>
        {footerVisibility && (
          <>
            <hr />
            <div className="modal-footer">
              <button
                className="modal-btn modal-btn-close"
                onClick={closeModal}
                disabled={saveBtnDisable}
              >
                {closeBtnTitle}
              </button>
              <button
                className="modal-btn modal-btn-save"
                onClick={onSave}
                disabled={saveBtnDisable}
              >
                {saveBtnDisable ? (
                  <Loader classname="btn-loading" />
                ) : (
                  saveBtnTitle
                )}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
UiModal.propTypes = {
  title: PropTypes.string,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  children: PropTypes.any,
  modalSize: PropTypes.string,
  updateisOpen: PropTypes.func,
  footerVisibility: PropTypes.bool,
  saveBtnTitle: PropTypes.string,
  closeBtnTitle: PropTypes.string,
  saveBtnDisable: PropTypes.bool,
};

export default UiModal;
