import React from 'react';
import { withRouter } from 'react-router';
import DropedownHeader from './DropedownComponentHeader';

function ConfigurationModule() {
  return (
    <div>
      <DropedownHeader />
    </div>
  );
}

export default withRouter(ConfigurationModule);
